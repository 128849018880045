import axios from "axios";
import Cookies from "universal-cookie";

const {REACT_APP_BASEURL_V2, REACT_APP_BASEURL_V1, REACT_APP_CLIENT_ID} = process.env;

const cookies = new Cookies();

const API = axios.create({
  baseURL: `${REACT_APP_BASEURL_V2}/int/ai-coach`,
  headers: {
    client_id: REACT_APP_CLIENT_ID,
    access_token: cookies.get("access_token"),
    user_timezone:  Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

export const APIfileUpload =  axios.create({
    baseURL: `${REACT_APP_BASEURL_V2}/int/ai-coach`,
    headers: {
        client_id: REACT_APP_CLIENT_ID,
        access_token: cookies.get("access_token"),
        user_timezone:  Intl.DateTimeFormat().resolvedOptions().timeZone,
       'Content-Type': 'multipart/form-data',
    },
});

export const APIv1 = axios.create({
  baseURL: `${REACT_APP_BASEURL_V1}`,
  headers: {
    client_id: REACT_APP_CLIENT_ID,
    user_timezone:  Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

API.interceptors.request.use(
  (req) =>{
      const token = (new Cookies()).get('access_token');
      if(token){
          req.headers['access_token'] = token; //Set Access Token
      }
      return req
  },
  (err) =>{
      return Promise.reject(err)
  }
);

API.interceptors.response.use(
  (req) =>{       
      return req
  },
  (err) =>{
      return Promise.reject(err)
  }
);
APIfileUpload.interceptors.request.use(
    (req) =>{
        const token = (new Cookies()).get('access_token');
        if(token){
            req.headers['access_token'] = token; //Set Access Token
        }
        return req
    },
    (err) =>{
        return Promise.reject(err)
    }
);
APIfileUpload.interceptors.response.use(
    (req) =>{
        return req
    },
    (err) =>{
        return Promise.reject(err)
    }
);

export default API;
