import React, { useContext, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Select from "react-select";
import DataContext from "../context/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRefresh } from "@fortawesome/free-solid-svg-icons";
import API from "./api/API";
import Swal from "sweetalert2";
import preloaderGif from "../assets/img/preloader.gif";

const FilterExercises = () => {
  const { workoutTypeList, workoutCategoryList, workoutMuscleList, workoutExerciseList, mapMuscleExerciseList } =
    useContext(DataContext);

  //Workout Objects
  const [typeObj, setTypeObj] = useState(workoutTypeList);
  const [categoryObj, setCategoryObj] = useState(workoutCategoryList);
  const [muscleObj, setMuscleObj] = useState(workoutMuscleList);

  const [exerciseList, setExerciseList] = useState(
    workoutExerciseList
  );
  const [mapMuscleExercise, setMapMuscleExercise] = useState(
    mapMuscleExerciseList
  );

  const [selectedExercise, setSelectedExercise] = useState([]);
  const [selectedExerciseObj, setSelectedExerciseObj] = useState([]);

  useEffect(() => {
    if (workoutTypeList) {
      const wtl = workoutTypeList.map((obj, index) => {
        return {
          ...obj,
          value: obj.id,
          label: obj.name,
        };
      });
      setTypeObj(wtl);
    }
  }, [workoutTypeList]);

  useEffect(() => {
    if (workoutCategoryList) {
      const wcl = workoutCategoryList.map((obj, index) => {
        return {
          ...obj,
          value: obj.id,
          label: obj.name,
        };
      });
      setCategoryObj(wcl);
    }
  }, [workoutCategoryList]);

  useEffect(() => {
    if (workoutMuscleList) {
      const wml = workoutMuscleList.map((obj) => {
        return {
          ...obj,
          value: obj.id,
          label: obj.name,
        };
      });
      setMuscleObj(wml);
    }
  }, [workoutMuscleList]);

  useEffect(() => {
    if (workoutExerciseList) {
      setExerciseList(workoutExerciseList);
    }
  }, [workoutExerciseList]);
  
  useEffect(() => {
    if (mapMuscleExerciseList) {
      setMapMuscleExercise(mapMuscleExerciseList);
    }
  }, [mapMuscleExerciseList]);

  const difficultOptions = [
    { value: "easy", label: "Easy" },
    { value: "medium", label: "Medium" },
    { value: "hard", label: "Hard" },
  ];

  const equipmentOptions = [
    { value: "dumbbell", label: "Dumbbell" },
    { value: "kettlebell", label: "Kettlebell" },
    { value: "band", label: "Band" },
  ];

  const [defaultTypes, setDefaultTypes] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [defaultMuscle, setDefaultMuscle] = useState([]);
  const [defaultDifficult, setDefaultDifficult] = useState([]);
  const [defaultEquipment, setDefaultEquipment] = useState([]);

  const [selectedWTypes, setSelectedWTypes] = useState([]);
  const [selectedWCategory, setSelectedWCategory] = useState([]);
  const [selectedMuscle, setSelectedMuscle] = useState([]);
  const [selectedDifficult, setSelectedDifficult] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState([]);

  const [filteredExercises, setFilteredExercises] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [typeShow, setTypeShow] = useState(false);

  const handleFilterExercise = () => {
    if (selectedWTypes.length > 0) {
      setIsLoading(true);
      API.post("/exercises-day", {
        wTypeIDs: selectedWTypes,
        wCategoryIDs: selectedWCategory,
        muscleIDs: selectedMuscle,
        exerciseDifficulty: selectedDifficult,
        equipments: selectedEquipment,
      })
        .then((response) => {
          let responseData = response.data.data.exercise_list;

          const uniqueExercises = [];

          responseData.forEach((obj) => {
            const exists = uniqueExercises.some(
              (item) => item.exercise_id === obj.exercise_id
            );

            if (!exists) {
              uniqueExercises.push(obj);
            }
          });

          setFilteredExercises(uniqueExercises);

          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        })
        .catch((error) => {
          Swal.fire(error.response.data.message, "", "warning");
          setIsLoading(false);
        });
    } else {
      Swal.fire("Please Select Workout Type", "", "warning");
    }
  };

  const handleResetFilter = () => {
    setDefaultTypes("");
    setDefaultCategory("");
    setDefaultMuscle("");
    setDefaultDifficult("");
    setDefaultEquipment("");

    setSelectedWTypes([]);
    setSelectedWCategory([]);
    setSelectedMuscle([]);
    setSelectedDifficult([]);
    setSelectedEquipment([]);

    setFilteredExercises([]);
  };

  const selectStyles = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };

  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  const Preloader = () => {
    return (
      <div className="preloader-section">
        <img src={preloaderGif} alt="Loading..." />
      </div>
    );
  };

  return (
    <div className="filter-exercise">
      <Row>
        <Col md={4}>
          <Card className="mb-3 border border-danger">
            <Card.Header className="bg-danger text-white">
              <h6 className="mb-0">Filter Exercises</h6>
            </Card.Header>
            <Card.Body className="text-start">
              <Row>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Type</Form.Label>
                    <Select
                      maxMenuHeight={200}
                      menuPlacement="auto"
                      className="react-select"
                      isMulti
                      name="w_type"
                      options={typeObj}
                      styles={selectStyles}
                      value={defaultTypes}
                      onChange={(e) => {
                        setDefaultTypes(e);
                        const tempArry = [];
                        e?.map((obj, index) => {
                          tempArry.push(obj.value);
                        });
                        setSelectedWTypes(tempArry);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Category</Form.Label>
                    <Select
                      className="react-select"
                      isMulti
                      name="w_category"
                      options={categoryObj}
                      value={defaultCategory}
                      styles={selectStyles}
                      onChange={(e) => {
                        setDefaultCategory(e);
                        const tempArry = [];
                        e?.map((obj, index) => {
                          tempArry.push(obj.value);
                        });
                        setSelectedWCategory(tempArry);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Muscle</Form.Label>
                    <Select
                      className="react-select"
                      isMulti
                      name="w_muscle"
                      options={muscleObj}
                      value={defaultMuscle}
                      styles={selectStyles}
                      onChange={(e) => {
                        setDefaultMuscle(e);
                        const tempArry = [];
                        e?.map((obj, index) => {
                          tempArry.push(obj.value);
                        });
                        setSelectedMuscle(tempArry);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Equipments</Form.Label>
                    <Select
                      className="react-select"
                      isMulti
                      name="equipments"
                      options={equipmentOptions}
                      value={defaultEquipment}
                      styles={selectStyles}
                      onChange={(e) => {
                        setDefaultEquipment(e);
                        const tempArry = [];
                        e?.map((obj, index) => {
                          tempArry.push(obj.value);
                        });
                        setSelectedEquipment(tempArry);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Difficulty</Form.Label>
                    <Select
                      className="react-select"
                      isMulti
                      name="difficult"
                      options={difficultOptions}
                      value={defaultDifficult}
                      styles={selectStyles}
                      onChange={(e) => {
                        setDefaultDifficult(e);
                        const tempArry = [];
                        e?.map((obj, index) => {
                          tempArry.push(obj.value);
                        });
                        setSelectedDifficult(tempArry);
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    className="w-100"
                    onClick={() => {
                      handleResetFilter();
                    }}
                  >
                    <FontAwesomeIcon icon={faRefresh} /> Reset Filter
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="w-100"
                    onClick={() => {
                      handleFilterExercise();
                    }}
                  >
                    <FontAwesomeIcon icon={faFilter} /> Filter
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          {isLoading ? (
            <Preloader />
          ) : (
            <Card className="mb-3 border border-danger">
              <Card.Header className="bg-danger text-white">
                <h6 className="mb-0">Exercise List</h6>
              </Card.Header>
              <Card.Body className="text-center">
                <h6>
                  {filteredExercises?.length ? (
                    <>( {filteredExercises?.length} Exercises Found )</>
                  ) : (
                    ""
                  )}
                </h6>
                {filteredExercises?.length ? (
                  filteredExercises?.map((data, d_index) => {
                    return (
                      <Card
                        className="mb-3 exercises-list"
                        key={d_index}
                        onClick={() => {
                          let selectedThis = mapMuscleExerciseList.filter(
                            (Obj) =>
                              parseInt(Obj.exercise_id) ===
                              parseInt(data.exercise_id)
                          );

                          let exerciseFilter = exerciseList?.find(
                            (Obj) =>
                              parseInt(Obj.id) === parseInt(data.exercise_id)
                          );

                          setSelectedExerciseObj(selectedThis);
                          setSelectedExercise(exerciseFilter);

                          setTypeShow(true);
                        }}
                      >
                        <Card.Header className="text-start">
                          <div className="d-flex justify-content-between">
                            <h5 className="mb-0">
                              {data.exercise_name}
                              <small> ( Rank: {data.exercise_rank} )</small>
                            </h5>
                            <h6 className="mb-0">
                              Reps/Min: {data.exercise_reps_per_minute}
                            </h6>
                          </div>

                          {data.exercise_equipment.length > 0 ? (
                            <div className="text-start mb-0 mt-2">
                              Equipments:
                              {data.exercise_equipment?.map((obj, index) => {
                                return (
                                  <>
                                    <Badge
                                      bg="primary"
                                      className="mx-1"
                                      key={index}
                                    >
                                      {data.exercise_equipment[index]}
                                    </Badge>
                                  </>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </Card.Header>

                        <Card.Body className="hide">
                          <div className="d-flex justify-content-between">
                            <h5>
                              <Badge bg="success" className="mx-2">
                                {data.wtype_name}
                              </Badge>
                            </h5>
                            <h5>
                              <Badge bg="info" className="mx-2">
                                {data.wcategory_name}
                              </Badge>
                            </h5>
                            <h5>
                              <Badge bg="warning" className="mx-2">
                                {data.muscle_name}
                              </Badge>
                            </h5>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })
                ) : (
                  <>
                    <div className="text-center">
                      <h4>/---- No Exercises Found ----/</h4>
                      <img
                        src={require("../assets/img/exercise-not-found.gif")}
                        alt=""
                        className=""
                        style={{ width: "auto", maxHeight: "250px" }}
                      />
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          )}

          {/* Modal Section */}
          <Modal size="lg" show={typeShow} onHide={() => setTypeShow(false)} className="overview-exercise">
            <Modal.Header closeButton>
              <Modal.Title>
                <h5 className="mb-0">Overview Exercise</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={4}>                
                  {urlRegex.test(selectedExercise.content_url) ? (
                  <video autoPlay loop className="exercise-video">
                    <source
                      src={selectedExercise.content_url}
                      type="video/mp4"
                    />
                  </video>) : (
                    <img src={selectedExercise.img_url} alt="Uploaded" className="w-100 exercise-video"/>
                  )}
                </Col>
                <Col md={8}>
                  <h4>
                    {selectedExercise.name}
                    <small>
                      <Badge
                        bg={
                          selectedExercise.difficulty === "easy"
                            ? "success"
                            : selectedExercise.difficulty === "medium"
                            ? "primary"
                            : "danger"
                        }
                        className="text-capitalize mx-2"
                      >
                        {selectedExercise.difficulty}
                      </Badge>
                    </small>
                  </h4>
                  <Row>
                    <Col md={6}>
                      <h6>Reps/Min: {selectedExercise.reps_per_minute}</h6>
                    </Col>
                    <Col md={6}>
                      <h6>Cal/Min: {selectedExercise.calories_per_minute}</h6>
                    </Col>
                  </Row>
                  {(selectedExercise.equipment?.length) ? 
                  (<Col md={12}>
                    <h6>
                      Equipment:
                      {selectedExercise.equipment?.map((obj, index) => {
                        return (
                          <Badge bg="primary" className="mx-1" key={index}>
                            {obj}
                          </Badge>
                        );
                      })}
                    </h6>
                  </Col>) : ""}
                  <Col md={12}>
                    <h6>Description: {selectedExercise.description}</h6>
                  </Col>
                  <div>
                    <Table striped bordered hover className="mt-3">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Category</th>
                          <th>Muscle</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedExerciseObj?.map((obj, index) => (
                          <tr key={index}>
                            <td>{obj.workoutType_name}</td>
                            <td>{obj.workoutCategory_name}</td>
                            <td>{obj.workoutMuscle_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                 
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          {/* End Modal Section */}
        </Col>
      </Row>
    </div>
  );
};

export default FilterExercises;
