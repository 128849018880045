import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import API from "../api/API";

const ImportModal = ({ show, onHide, onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [messageResponse, setMessageResponse] = useState(null);
  const [validationResult, setValidationResult] = useState(null);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [validationCompleted, setValidationCompleted] = useState(false);
  const [fileId, setFileId] = useState("");
  const [exerciseIds, setExerciseIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setValidationResult(null);
    setValidationCompleted(false);
    setUploadCompleted(false);
    setMessageResponse(null);
  };

  const handleValidate = async () => {
    if (selectedFile) {
      setLoading(true);
      const response = await onFileUpload(selectedFile);

      if (response.error) {
        setMessageResponse(response.error);
        setLoading(false);
        return;
      }
      setValidationResult(response.data.data);
      setMessageResponse(response.data.message + "👍");
      setValidationCompleted(true);
      setFileId(response.data.data.fileId);
      setExerciseIds(response.data.data.exercise_ids);
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    API.post("/exercise-information", {
      file_id: fileId,
      exercise_ids: exerciseIds,
    })
      .then((response) => {
        setMessageResponse(response.data.message + " 👍");
        setUploadCompleted(true);
        setLoading(false);
        setValidationResult(null);
      })
      .catch((error) => {
        console.log(error);
        setMessageResponse("Upload failed ☹️☹️☹️");
        setLoading(false);
        setValidationResult(null);
      });
  };

  const handleFinish = () => {
    setSelectedFile(null);
    setValidationResult(null);
    setValidationCompleted(false);
    setUploadCompleted(false);
    setMessageResponse(null);
    setExerciseIds([]);
    setLoading(false);

    onHide();
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setValidationResult(null);
    setValidationCompleted(false);
    setUploadCompleted(false);
    setMessageResponse(null);
    setExerciseIds([]);
    setLoading(false);

    onHide();
  };

  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Import Excel File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Select Excel File</Form.Label>
            <Form.Control
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
            />
          </Form.Group>
        </Form>
        {messageResponse && <p className="fs-5">{messageResponse}</p>}
        {validationResult && validationResult && (
          <div>
            <p>Validation Result:</p>
            <p>Updated Exercises: {validationResult?.updated_exercise}</p>
            <p>Created Exercises: {validationResult?.new_exercise}</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {loading && <Spinner animation="border" variant="primary" />}
        {validationCompleted && !uploadCompleted && (
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        )}
        {uploadCompleted && (
          <Button variant="success" onClick={handleFinish}>
            Finish
          </Button>
        )}
        {!validationCompleted && (
          <Button
            variant="info"
            onClick={handleValidate}
            disabled={!selectedFile}
          >
            Validate
          </Button>
        )}
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportModal;
