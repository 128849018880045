import React, { Fragment, useContext, useEffect, useState } from "react";
import { Tab, Nav, Table, Button, Row, Col } from "react-bootstrap";
import { Modal, Form, Tooltip, OverlayTrigger   } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faClone,
  faDumbbell,
  faEdit,
  faList,
  faPlusCircle,
  faRunning,
  faTrash,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import API, { APIfileUpload } from "./api/API";
import DataContext from "../context/DataContext";
import Select from "react-select";
import imageCompression from "browser-image-compression";
import preloaderGif from "../assets/img/preloader-3.gif";
import * as XLSX from "xlsx";
import ImportModal from "./Modals/ImportModal";
import DataTable from "react-data-table-component";

const CreateEntity = () => {
  const [isEdit, setIsEdit] = useState(false);
  const {
    REACT_APP_AIC_EXERCISE_SAMPLE_EXERCISE_DESCRIPTION,
    REACT_APP_AIC_EXERCISE_SAMPLE_EXERCISE_CONTENT_URL,
    REACT_APP_AIC_EXERCISE_SAMPLE_EXERCISE_IMG_URL,
  } = process.env;
  const {
    workoutTypeList,
    setWorkoutTypeList,
    workoutCategoryList,
    setWorkoutCategoryList,
    workoutMuscleList,
    setWorkoutMuscleList,
    workoutExerciseList,
    setWorkoutExerciseList,
    mapTypeCategoryList,
    setMapTypeCategoryList,
    mapCategoryMuscleList,
    setMapCategoryMuscleList,
    mapMuscleExerciseList,
    setMapMuscleExerciseList,
  } = useContext(DataContext);

  //Preloader
  const [isLoading, setIsLoading] = useState(false);
  const Preloader = () => {
    return (
      <div className="preloader-section">
        <img src={preloaderGif} alt="Loading..." />
      </div>
    );
  };

  /* Workout Type Creation*/
  const [workoutType, setWorkoutType] = useState("");
  const [workoutTypeID, setWorkoutTypeID] = useState(0); // Create or Edit
  const [typeShow, setTypeShow] = useState(false);
  const handleSubmitType = (id) => {
    const filterObj = workoutTypeList.filter(
      (obj) =>
        obj.name.toLowerCase() === workoutType.toLowerCase() && obj.id !== id
    );

    if (workoutType !== "" && !filterObj.length) {
      if (id === 0) {
        setIsLoading(true);
        API.post("/workout-types", { name: workoutType })
          .then((response) => {
            let responseData = response.data.data.aic_wtype;
            setWorkoutTypeList([...workoutTypeList, responseData]);
            setWorkoutType("");
            setTypeShow(false);
            setIsLoading(false);
            setWorkoutTypeID(0);
          })
          .catch((error) => {
            Swal.fire(error.response.data.message, "", "warning");
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        API.patch(`/workout-types/${id}`, { name: workoutType })
          .then((response) => {
            let responseData = response.data.data.aic_wtype;
            const updatedList = workoutTypeList.map((obj) => {
              if (obj.id === id) {
                return responseData;
              } else {
                return obj;
              }
            });

            setWorkoutTypeList(updatedList);
            setWorkoutType("");
            setTypeShow(false);
            setIsLoading(false);
            setWorkoutTypeID(0);
          })
          .catch((error) => {
            Swal.fire(error.response.data.message, "", "warning");
            setIsLoading(false);
          });
      }
    } else {
      Swal.fire("Name Already Exists", "", "info");
    }
  };

  /* Workout Category Creation*/
  const [workoutCategory, setWorkoutCategory] = useState("");
  const [workoutCategoryID, setWorkoutCategoryID] = useState(0);
  const [categoryShow, setCategoryShow] = useState(false);

  const handleSubmitCategory = (id) => {
    const filterObj = workoutCategoryList.filter(
      (obj) =>
        obj.name.toLowerCase() === workoutCategory.toLowerCase() &&
        obj.id !== id
    );

    if (workoutCategory !== "" && !filterObj.length) {
      if (id === 0) {
        setIsLoading(true);
        API.post("/workout-categories", { name: workoutCategory })
          .then((response) => {
            let responseData = response.data.data.aic_wcategory;
            setWorkoutCategoryList([...workoutCategoryList, responseData]);
            setWorkoutCategory("");
            setCategoryShow(false);
            setIsLoading(false);
            setWorkoutCategoryID(0);
          })
          .catch((error) => {
            Swal.fire(error.response.data.message, "", "warning");
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        API.patch(`/workout-categories/${id}`, {
          category_id: id,
          name: workoutCategory,
        })
          .then((response) => {
            let responseData = response.data.data.aic_wcategory;
            const updatedList = workoutCategoryList.map((obj) => {
              if (obj.id === id) {
                return responseData;
              } else {
                return obj;
              }
            });
            setWorkoutCategoryList(updatedList);
            setWorkoutCategory("");
            setCategoryShow(false);
            setIsLoading(false);
            setWorkoutCategoryID(0);
          })
          .catch((error) => {
            Swal.fire(error.response.data.message, "", "warning");
            setIsLoading(false);
          });
      }
    } else {
      Swal.fire("Name Already Exists", "", "info");
    }
  };

  /* Muscle Creation*/

  const [workoutMuscle, setWorkoutMuscle] = useState("");
  const [workoutMuscleID, setWorkoutMuscleID] = useState(0);
  const [muscleShow, setMuscleShow] = useState(false);

  const handleSubmitMuscle = (id) => {
    const filterObj = workoutMuscleList.filter(
      (obj) =>
        obj.name.toLowerCase() === workoutMuscle.toLowerCase() && obj.id !== id
    );

    if (workoutMuscle.trim().length > 0 && !filterObj.length) {
      if (id === 0) {
        setIsLoading(true);
        API.post("/workout-muscles", { name: workoutMuscle })
          .then((response) => {
            if (response.status === 200) {
              let responseData = response.data.data.aic_muscle;
              setWorkoutMuscleList([...workoutMuscleList, responseData]);
              setWorkoutMuscle("");
              setMuscleShow(false);
              setIsLoading(false);
              setWorkoutMuscleID(0);
            }
          })
          .catch((error) => {
            Swal.fire(error.response.data.message, "", "warning");
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        API.patch(`/workout-muscles/${id}`, { name: workoutMuscle })
          .then((response) => {
            let responseData = response.data.data.aic_muscle;
            const updatedList = workoutMuscleList.map((obj) => {
              if (obj.id === id) {
                return responseData;
              } else {
                return obj;
              }
            });
            setWorkoutMuscleList(updatedList);
            setWorkoutMuscle("");
            setMuscleShow(false);
            setIsLoading(false);
            setWorkoutMuscleID(0);
          })
          .catch((error) => {
            Swal.fire(error.response.data.message, "", "warning");
            setIsLoading(false);
          });
      }
    } else {
      Swal.fire("Name Already Exists", "", "info");
    }
  };

  /* Exercise Creation*/

  const [workoutExercise, setWorkoutExercise] = useState("");
  const [workoutExerciseDesc, setWorkoutExerciseDesc] = useState("");
  const [workoutExerciseEquipment, setWorkoutExerciseEquipment] = useState([]);
  const [workoutExerciseDiff, setWorkoutExerciseDiff] = useState("");
  const [workoutExerciseImage, setWorkoutExerciseImage] = useState("");
  const [workoutExerciseIsTimed, setWorkoutExerciseIsTimed] = useState(false);
  const [workoutExerciseReps, setWorkoutExerciseReps] = useState("");
  const [workoutExerciseCalories, setWorkoutExerciseCalories] = useState("");
  const [workoutExerciseMet, setWorkoutExerciseMet] = useState("");
  const [workoutExerciseContentUrl, setWorkoutExerciseContentUrl] =
    useState("");
  const [workoutExerciseInstructions, setWorkoutExerciseInstructions] =
    useState("");
  const [workoutExerciseID, setWorkoutExerciseID] = useState(0);
  const [exerciseShow, setExerciseShow] = useState(false);

  const [exerciseImageFile, setExerciseImageFile] = useState("");

  useEffect(() => {
    setDefaultExerciseImage(
      "https://smitchcare.azureedge.net/aicoach/activity/workout/exercise/default.png"
    );
  }, []);

  useEffect(() => {
    setWorkoutExerciseList(
      workoutExerciseList?.map((data) => ({
        ...data,
        muscle_length: mapMuscleExerciseList.filter(
          (obj) => parseInt(obj.exercise_id) === parseInt(data.id)
        ).length,
      }))
    );
  }, [mapMuscleExerciseList]);

  const handleExerciseReset = () => {
    setSelectedType("");
    setSelectedCategory("");
    setSelectedMuscle("");
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setExerciseImageFile(file);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };

    const reader = new FileReader();

    try {
      if (file) {
        const compressedFile = await imageCompression(file, options);
        reader.readAsDataURL(compressedFile); //URL.createObjectURL(file)
      }
    } catch (error) {
      console.error("Image compression error:", error);
    }

    reader.onloadend = () => {
      setWorkoutExerciseImage(reader.result);
    };
  };

  const handleExerciseInstructionsChange = (event) => {
    const { value } = event.target;
    setWorkoutExerciseInstructions(value);
  };

  const splitByNewline = () => {
    return workoutExerciseInstructions?.split("\n");
  };

  const handleSubmitExercise = (id) => {
    if (workoutExercise !== "") {
      // FormData-File
      const formData = new FormData();
      formData.append("image", exerciseImageFile);
      formData.append("name", workoutExercise);
      formData.append("difficulty", workoutExerciseDiff);
      formData.append("description", workoutExerciseDesc);
      formData.append("is_timed", workoutExerciseIsTimed);
      formData.append("reps_per_minute", workoutExerciseReps);
      formData.append("calories_per_minute", workoutExerciseCalories);
      formData.append("met", workoutExerciseMet);
      formData.append("content_url", workoutExerciseContentUrl);
      formData.append("img_url", workoutExerciseImage);
      formData.append("instructions", workoutExerciseInstructions);

      workoutExerciseEquipment.forEach((value, index) => {
        formData.append(`equipment[${index}]`, value);
      });

      if (id === 0) {
        const filterObj = workoutExerciseList.filter(
          (obj) => obj.name.toLowerCase() === workoutExercise.toLowerCase()
        );

        if (filterObj.length <= 0) {
          let exerciseImage = workoutExerciseImage;

          if (!workoutExerciseImage) {
            exerciseImage = defaultExerciseImage;
          }
          setIsLoading(true);
          API.post("/workout-exercises", {
            name: workoutExercise,
            difficulty: workoutExerciseDiff,
            description: workoutExerciseDesc,
            equipment: workoutExerciseEquipment,
            img_url: exerciseImage,
            is_timed: workoutExerciseIsTimed,
            reps_per_minute: workoutExerciseReps,
            calories_per_minute: workoutExerciseCalories,
            met: workoutExerciseMet,
            content_url: workoutExerciseContentUrl,
            instructions: workoutExerciseInstructions,
          })
            .then((response) => {
              let responseData = response.data.data.aic_exercise;

              setSelectedExerciseName(responseData.name);
              setSelectedExercise(responseData.id);
              setWorkoutExerciseID(responseData.id);
              setWorkoutExerciseImage(responseData.img_url);             

              setWorkoutExerciseList([...workoutExerciseList, responseData]);
              setIsEdit(true);
              setIsLoading(false);
              //handleReset();
              //setExerciseShow(false);
            })
            .catch((error) => {
              Swal.fire(error.response.data.message, "", "warning");
              setIsLoading(false);
            });
        } else {
          Swal.fire("Workout Already Exists", "", "info");
        }
      } else {
        const filterObj = workoutExerciseList.filter(
          (obj) =>
            obj.name.toLowerCase() === workoutExercise.toLowerCase() &&
            parseInt(obj.id) !== parseInt(id)
        );

        if (filterObj.length <= 0) {
          setIsLoading(true);
          API.patch(`/workout-exercises/${id}`, {
            name: workoutExercise,
            difficulty: workoutExerciseDiff,
            description: workoutExerciseDesc,
            equipment: workoutExerciseEquipment,
            img_url: workoutExerciseImage,
            is_timed: workoutExerciseIsTimed,
            reps_per_minute: workoutExerciseReps,
            calories_per_minute: workoutExerciseCalories,
            met: workoutExerciseMet,
            content_url: workoutExerciseContentUrl,
            instructions: workoutExerciseInstructions,
          })
            .then((response) => {
              const responseData = response.data.data.aic_exercise;
              const exerciseMapped = workoutExerciseList.find(
                (obj) => parseInt(obj.id) === parseInt(responseData.id)
              );

              if (exerciseMapped) {
                responseData.muscle_length = exerciseMapped.muscle_length;
              }

              const updatedList = workoutExerciseList.map((obj) =>
                obj.id === id ? responseData : obj
              );
              setWorkoutExerciseList(updatedList);
              
              handleReset();
              setExerciseShow(false);
              setIsLoading(false);
              setWorkoutExerciseID(0);
            })
            .catch((error) => {
              Swal.fire(error.response.data.message, "", "warning");
              setIsLoading(false);
            });
        } else {
          Swal.fire("Workout Already Exists", "", "info");
        }
      }
    } else {
      Swal.fire("Please Check Entered Values", "", "info");
    }
  };

  const handleReset = () => {
    setWorkoutExercise("");
    setWorkoutExerciseDesc("");
    setWorkoutExerciseEquipment([]);
    setDefaultExerciseEquipment([]);
    setWorkoutExerciseDiff("");
    setWorkoutExerciseImage("");
    setWorkoutExerciseReps("");
    setWorkoutExerciseMet("");
    setWorkoutExerciseInstructions("");
    setWorkoutExerciseContentUrl("");
    setWorkoutExerciseIsTimed(false);
  };

  /* Common Delete Entity */

  const handleDelete = (id, jsonURL) => {
    Swal.fire({
      title: "Do you want to Delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete(`/${jsonURL}/${id}`).then((response) => {
          Swal.fire("Deleted Successfully!", "", "success");

          if (jsonURL === "workout-types") {
            setWorkoutTypeList(workoutTypeList.filter((obj) => obj.id !== id));
          } else if (jsonURL === "workout-categories") {
            setWorkoutCategoryList(
              workoutCategoryList.filter((obj) => obj.id !== id)
            );
          } else if (jsonURL === "workout-muscles") {
            setWorkoutMuscleList(
              workoutMuscleList.filter((obj) => obj.id !== id)
            );
          } else if (jsonURL === "workout-exercises") {
            setWorkoutExerciseList(
              workoutExerciseList.filter((obj) => obj.id !== id)
            );
          }
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });

    /* */
  };

  /* Exercise Mapping */

  const [typeObj, setTypeObj] = useState(workoutTypeList);
  const [categoryObj, setCategoryObj] = useState(workoutCategoryList);
  const [muscleObj, setMuscleObj] = useState(workoutMuscleList);

  const [exerciseRelationship, setExerciseRelationship] = useState(
    mapMuscleExerciseList
  );

  const [selectedType, setSelectedType] = useState("");
  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedMuscle, setSelectedMuscle] = useState("");
  const [selectedMuscleName, setSelectedMuscleName] = useState("");
  const [selectedExercise, setSelectedExercise] = useState("");
  const [selectedExerciseName, setSelectedExerciseName] = useState("");

  const [searchWType, setSearchWType] = useState("");
  const [searchWCategory, setSearchWCategory] = useState("");
  const [searchWMuscle, setSearchWMuscle] = useState("");
  const [searchWExercise, setSearchWExercise] = useState("");

  useEffect(() => {
    if (workoutTypeList) {
      setTypeObj(workoutTypeList);
    }
  }, [workoutTypeList]);

  useEffect(() => {
    if (workoutCategoryList) {
      setCategoryObj(workoutCategoryList);
    }
  }, [workoutCategoryList]);

  useEffect(() => {
    if (workoutMuscleList) {
      setMuscleObj(workoutMuscleList);
    }
  }, [workoutMuscleList]);

  useEffect(() => {
    if (mapMuscleExerciseList.length > 0) {
      setExerciseRelationship(mapMuscleExerciseList);
    }
  }, [mapMuscleExerciseList]);


  const addRelationship = () => {
    if (
      selectedExercise !== "" &&
      selectedType !== "" &&
      selectedCategory !== "" &&
      selectedMuscle !== ""
    ) {
      let relationshipObj = exerciseRelationship.filter(
        (Obj) =>
          parseInt(Obj.workoutExercise_id) === parseInt(selectedExercise) &&
          parseInt(Obj.workoutType_id) === parseInt(selectedType) &&
          parseInt(Obj.workoutCategory_id) === parseInt(selectedCategory) &&
          parseInt(Obj.workoutMuscle_id) === parseInt(selectedMuscle)
      );

      if (relationshipObj.length === 0) {
        API.post("/exercise/map", {
          wtype_id: selectedType,
          wcategory_id: selectedCategory,
          muscle_id: selectedMuscle,
          exercise_id: selectedExercise,
        })
          .then((response) => {
            let responseData = response.data.data;

            let responseObj = {
              id: responseData.aic_exercise_muscle.id,
              muscle_exercise_id: responseData.aic_exercise_muscle.id,
              wcategory_muscle_id:
                responseData.aic_exercise_muscle.wcategory_muscle_id,

              wtype_wcategory_id:
                responseData.aic_wcategory_muscle.wtype_wcategory_id,

              workoutType_id: responseData.aic_wtype_wcategory.wtype_id,
              workoutType_name: selectedTypeName,

              workoutCategory_id: responseData.aic_wtype_wcategory.wcategory_id,
              workoutCategory_name: selectedCategoryName,

              muscle_id: responseData.aic_wcategory_muscle.muscle_id,
              workoutMuscle_id: responseData.aic_wcategory_muscle.muscle_id,
              workoutMuscle_name: selectedMuscleName,

              exercise_id: responseData.aic_exercise_muscle.exercise_id,
              workoutExercise_id: responseData.aic_exercise_muscle.exercise_id,
              workoutExercise_name: selectedExerciseName,
            };

            let wtype_wcategory_Obj = {
              id: responseData.aic_wtype_wcategory.id,
              wtype_id: responseData.aic_wtype_wcategory.wtype_id,
              workoutType_name: selectedTypeName,
              wcategory_id: responseData.aic_wtype_wcategory.wcategory_id,
              workoutCategory_name: selectedCategoryName,
            };

            setMapTypeCategoryList([
              ...mapTypeCategoryList,
              wtype_wcategory_Obj,
            ]);
            setMapCategoryMuscleList([...mapCategoryMuscleList, responseObj]);
            setMapMuscleExerciseList([...mapMuscleExerciseList, responseObj]);
            setExerciseRelationship([...exerciseRelationship, responseObj]);
            handleExerciseReset();
          })
          .catch((error) => {
            Swal.fire(error.response.data.message, "", "warning");
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Already Mapped",
          showConfirmButton: false,
          timer: 800,
        });
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please Select Relationship Entities",
        showConfirmButton: false,
        timer: 800,
      });
    }
  };

  const cloneRelationship = (
    selectedType,
    selectedCategory,
    selectedMuscle,
    selectedExercise,
    tempObj_id
  ) => {
    if (
      selectedExercise !== "" &&
      selectedType !== "" &&
      selectedCategory !== "" &&
      selectedMuscle !== ""
    ) {
      let relationshipObj = exerciseRelationship.filter(
        (Obj) =>
          parseInt(Obj.workoutExercise_id) === parseInt(selectedExercise) &&
          parseInt(Obj.workoutType_id) === parseInt(selectedType) &&
          parseInt(Obj.workoutCategory_id) === parseInt(selectedCategory) &&
          parseInt(Obj.workoutMuscle_id) === parseInt(selectedMuscle)
      );

      if (relationshipObj.length === 0) {
        API.post("/exercise/map", {
          wtype_id: selectedType,
          wcategory_id: selectedCategory,
          muscle_id: selectedMuscle,
          exercise_id: selectedExercise,
        })
          .then((response) => {
            let responseData = response.data.data;

            let currentTempObj = exerciseTempMapping.find((Obj) => {
              return parseInt(Obj.id) === parseInt(tempObj_id);
            });

            let responseObj = {
              id: responseData.aic_exercise_muscle.id,
              muscle_exercise_id: responseData.aic_exercise_muscle.id,
              wcategory_muscle_id:
                responseData.aic_exercise_muscle.wcategory_muscle_id,

              wtype_wcategory_id:
                responseData.aic_wcategory_muscle.wtype_wcategory_id,

              workoutType_id: responseData.aic_wtype_wcategory.wtype_id,
              workoutType_name: currentTempObj.workoutType_name,

              workoutCategory_id: responseData.aic_wtype_wcategory.wcategory_id,
              workoutCategory_name: currentTempObj.workoutCategory_name,

              muscle_id: responseData.aic_wcategory_muscle.muscle_id,
              workoutMuscle_id: responseData.aic_wcategory_muscle.muscle_id,
              workoutMuscle_name: currentTempObj.workoutMuscle_name,

              exercise_id: responseData.aic_exercise_muscle.exercise_id,
              workoutExercise_id: responseData.aic_exercise_muscle.exercise_id,
              workoutExercise_name: currentTempObj.workoutExercise_name,
            };

            let WtypeWCategoryCheck = mapTypeCategoryList.filter((Obj) => {
              return (
                Obj.wtype_id === responseData.aic_wtype_wcategory.wtype_id &&
                Obj.wcategory_id ===
                  responseData.aic_wtype_wcategory.wcategory_id
              );
            });

            if (WtypeWCategoryCheck.length === 0) {
              let wtype_wcategory_Obj = {
                id: responseData.aic_wtype_wcategory.id,
                wtype_id: responseData.aic_wtype_wcategory.wtype_id,
                workoutType_name: selectedTypeName,
                wcategory_id: responseData.aic_wtype_wcategory.wcategory_id,
                workoutCategory_name: selectedCategoryName,
              };

              setMapTypeCategoryList([
                ...mapTypeCategoryList,
                wtype_wcategory_Obj,
              ]);
            }

            setMapCategoryMuscleList([...mapCategoryMuscleList, responseObj]);
            setMapMuscleExerciseList([...mapMuscleExerciseList, responseObj]);
            setExerciseRelationship([...exerciseRelationship, responseObj]);

            /* Remove Temp Object */
            let removeTempObj = exerciseTempMapping.filter((Obj) => {
              return parseInt(Obj.id) !== parseInt(tempObj_id);
            });
            setExerciseTempMapping(removeTempObj);
            handleExerciseReset();
          })
          .catch((error) => {
            Swal.fire(error.response.data.message, "", "warning");
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Already Mapped",
          showConfirmButton: false,
          timer: 800,
        });
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Please Select Relationship Entities",
        showConfirmButton: false,
        timer: 800,
      });
    }
  };

  const removeRelationship = (
    muscle_exercise_id,
    wcategory_muscle_id,
    typeId,
    categoryId,
    muscleId,
    exerciseId
  ) => {
    if (
      muscle_exercise_id !== "" ||
      exerciseId !== "" ||
      typeId !== "" ||
      categoryId !== "" ||
      muscleId !== ""
    ) {
      // let relationshipObj = exerciseRelationship.filter((Obj) => {
      //   return (
      //     parseInt(Obj.wcategory_muscle_id) === parseInt(wcategory_muscle_id) &&
      //     parseInt(Obj.workoutExercise_id) === parseInt(exerciseId)
      //   );
      // });

      API.delete(`/exercise/map/${muscle_exercise_id}`, {
        data: {
          wtype_id: typeId,
          wcategory_id: categoryId,
          muscle_id: muscleId,
          exercise_id: exerciseId,
        },
      }).then((response) => {
        let responseData = response.data.data;

        if (responseData.aic_exercise_muscle !== null) {
          let removeDeletedObj = mapMuscleExerciseList.filter((Obj) => {
            return (
              parseInt(Obj.id) !== parseInt(responseData.aic_exercise_muscle.id)
            );
          });

          setMapMuscleExerciseList(removeDeletedObj);

          if (responseData.aic_wcategory_muscle !== null) {
            let removeDeletedObj = mapCategoryMuscleList.filter((Obj) => {
              return (
                parseInt(Obj.id) !==
                parseInt(responseData.aic_wcategory_muscle.id)
              );
            });
            setMapCategoryMuscleList(removeDeletedObj);

            if (responseData.aic_wtype_wcategory !== null) {
              let removeDeletedObj = mapTypeCategoryList.filter((Obj) => {
                return (
                  parseInt(Obj.id) !==
                  parseInt(responseData.aic_wtype_wcategory.id)
                );
              });
              setMapTypeCategoryList(removeDeletedObj);
            }
          }
        }

        let removeDeletedData = exerciseRelationship.filter((Obj) => {
          return parseInt(Obj.id) !== parseInt(muscle_exercise_id);
        });
        setExerciseRelationship(removeDeletedData);
      });

      //handleExerciseReset();
    }
  };

  const equipmentOptions = [
    { value: "dumbbell", label: "Dumbbell" },
    { value: "kettlebell", label: "Kettlebell" },
    { value: "band", label: "Band" },
  ];

  const [defaultExerciseEquipment, setDefaultExerciseEquipment] = useState([]);

  const [defaultExerciseImage, setDefaultExerciseImage] = useState("");

  //Sort Config
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortTable = (key) => {
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...workoutExerciseList].sort((a, b) => {
      if (key === "id") {
        a[key] = parseInt(a[key]);
        b[key] = parseInt(b[key]);
      }

      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setWorkoutExerciseList(sortedData);
    setSortConfig({ key, direction });
  };

  /* Clone Exercise Mapping */

  const [exerciseIsTemp, setExerciseIsTemp] = useState(false);
  const [exerciseTempMapping, setExerciseTempMapping] = useState([]);

  /* Export */

  const flatten = (value) => {
    const flattened = {};
    value.forEach((item, i) => {
      Object.keys(item).forEach((key) => {
        flattened[`${key}_${i + 1}`] = item[key];
      });
    });
    return flattened;
  };
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}_${month}_${year}_${hours}_${minutes}_${seconds}`;
  }

  const handleExport = () => {
    API.get("/exercise-information")
      .then((response) => {
        const flattenedData = response.data.data.aic_exercise.map((item) => {
          let flattenItem = {
            id: item.id,
            name: item.name,
            description: item.description,
            is_timed: item.is_timed,
            img_url: item.img_url,
            content_url: item.content_url,
            equipment: item.equipment.join(","),
            reps_per_minute: item.reps_per_minute,
            met: item.met,
            difficulty: item.difficulty,
            instructions: item.instructions,
          };
          if(item.is_timed){
            flattenItem.is_timed= "Yes"
          }else{
            flattenItem.is_timed="No"
          }
          if (item && item.mapvalues) {
            flattenItem = { ...flattenItem, ...flatten(item.mapvalues) };
          }
          if (
            item.description ===
            REACT_APP_AIC_EXERCISE_SAMPLE_EXERCISE_DESCRIPTION
          ) {
            flattenItem.description = "";
          }
          if (item.img_url === REACT_APP_AIC_EXERCISE_SAMPLE_EXERCISE_IMG_URL) {
            flattenItem.img_url = "";
          }
          if (
            item.content_url ===
            REACT_APP_AIC_EXERCISE_SAMPLE_EXERCISE_CONTENT_URL
          ) {
            flattenItem.content_url = "";
          }
          return flattenItem;
        });

        const workbook = XLSX.utils.book_new();

        const exerciseWorksheet = XLSX.utils.json_to_sheet(flattenedData);
        XLSX.utils.book_append_sheet(
          workbook,
          exerciseWorksheet,
          "Exercise Data"
        );
        const now = new Date();
        const formattedDate = formatDate(now);
        XLSX.writeFile(workbook, `AICoach_Exercise_Data_${formattedDate}.xlsx`);
      })
      .catch((error) => {
        console.log(error);
        return error.message;
      });
  };
  // import exercises
  const [importModalOpen, setImportModalOpen] = useState(false);

  const handleImport = () => {
    setImportModalOpen(true);
  };

  const handleModalClose = () => {
    setImportModalOpen(false);
  };
  const handleFileUpload = async (file) => {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await APIfileUpload.post(
        "/validate/exercise-information",
        formData
      );
      return response;
    } catch (error) {
      return { error: "Not able to Validate  ☹️☹️☹️" };
    }
  };

  /* Datatable */

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  //Type
  const typeColumns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      sortFunction: (a, b) => parseInt(a.id) - parseInt(b.id),
      width: "15%",
      wrap: true,
    },
    {
      name: "Type Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (row) => (
        <>
          <Button
            variant="outline-primary"
            size="sm"
            className="mx-2"
            disabled={row.is_system_generated}
            onClick={() => {
              setIsEdit(true);
              setWorkoutType(row.name);
              setTypeShow(true);
              setWorkoutTypeID(row.id);
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit
          </Button>

          <Button
            variant="outline-danger"
            size="sm"
            className="mx-2"
            disabled={row.is_system_generated}
            onClick={() => {
              handleDelete(row.id, "workout-types");
            }}
          >
            <FontAwesomeIcon icon={faTrash} /> Delete
          </Button>
        </>
      ),
    },
  ];                   

  const [dtTypeList, setDtTypeList] = useState([]);

  useEffect(() => {
    if (workoutTypeList) {
      let dtList = workoutTypeList.filter(
        (item) =>
          searchWType === "" ||
          item.name.toLowerCase().includes(searchWType.toLowerCase())
      );

      setDtTypeList(dtList);
    }
  }, [workoutTypeList, searchWType]);

  //Category
  const categoryColumns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      sortFunction: (a, b) => parseInt(a.id) - parseInt(b.id),
      width: "15%",
      wrap: true,
    },
    {
      name: "Category Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (row) => (
        <>
          <Button
            variant="outline-primary"
            size="sm"
            className="mx-2"
            disabled={row.is_system_generated}
            onClick={() => {
              setIsEdit(true);
              setWorkoutCategory(row.name);
              setCategoryShow(true);
              setWorkoutCategoryID(row.id);
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit
          </Button>
          <Button
            variant="outline-danger"
            size="sm"
            className="mx-2"
            disabled={row.is_system_generated}
            onClick={() => {
              handleDelete(row.id, "workout-categories");
            }}
          >
            <FontAwesomeIcon icon={faTrash} /> Delete
          </Button>
        </>
      ),
    },
  ];                   

  const [dtCategoryList, setDtCategoryList] = useState([]);

  useEffect(() => {
    if (workoutCategoryList) {
      let dtList = workoutCategoryList.filter(
        (item) =>
          searchWCategory === "" ||
          item.name.toLowerCase().includes(searchWCategory.toLowerCase())
      );

      setDtCategoryList(dtList);
    }
  }, [workoutCategoryList, searchWCategory]);


  // Muscle
  const muscleColumns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      sortFunction: (a, b) => parseInt(a.id) - parseInt(b.id),
      width: "15%",
      wrap: true,
    },
    {
      name: "Muscle Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (row) => (
        <>
          <Button
            variant="outline-primary"
            size="sm"
            className="mx-2"
            disabled={row.is_system_generated}
            onClick={() => {
              setIsEdit(true);
              setWorkoutMuscle(row.name);
              setMuscleShow(true);
              setWorkoutMuscleID(row.id);
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit
          </Button>
          <Button
            variant="outline-danger"
            size="sm"
            className="mx-2"
            disabled={row.is_system_generated}
            onClick={() => {
              handleDelete(row.id, "workout-muscles");
            }}
          >
            <FontAwesomeIcon icon={faTrash} /> Delete
          </Button>
        </>
      ),
    },
  ];                   

  const [dtMuscleList, setDtMuscleList] = useState([]);

  useEffect(()=>{
    if(workoutMuscleList){

      let dtList = workoutMuscleList.filter(
        (item) =>
          searchWMuscle === "" ||
          item.name.toLowerCase().includes(searchWMuscle.toLowerCase())
      );

      setDtMuscleList(dtList);
    }

  },[workoutMuscleList, searchWMuscle]);

  // Exercise
  const exerciseColumns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      sortFunction: (a, b) => parseInt(a.id) - parseInt(b.id),
      width: "10%",
      wrap: true,
    },
    {
      name: "Workout Name",
      selector: (row) => row.name,
      sortable: true,
      width: "25%",
    },
    {
      name: "Difficulty",
      selector: (row) => row.difficulty,
      sortable: true,
      width: "10%",
    },
    {
      name: "Equipment",
      selector: (row) => (row.equipment.toString()),
      sortable: true,
      width: "25%",
    },
    {
      name: "Mapped",
      selector: (row) => row.muscle_length,
      sortable: true,
      width: "10%",
    },
    {
      name: "Action",
      sortable: false,
      width: "20%",
      cell: (row) => (
        <>
          <OverlayTrigger overlay={renderTooltip("Clone Exercise")}>
            <Button
              variant="outline-success"
              size="sm"
              className="mx-2"
              onClick={() => {
                setIsEdit(false);
                setWorkoutExercise("Copy of " + row.name);
                setWorkoutExerciseDesc(row.description);
                setWorkoutExerciseDiff(row.difficulty);
                setWorkoutExerciseImage(row.img_url);
                setWorkoutExerciseIsTimed(row.is_timed);
                setWorkoutExerciseReps(row.reps_per_minute.toString());
                setWorkoutExerciseCalories(row.calories_per_minute.toString());
                setWorkoutExerciseMet(row.met.toString());
                setWorkoutExerciseContentUrl(row.content_url);
                const result = equipmentOptions.filter((data) =>
                  row.equipment.includes(data.value)
                );
                setDefaultExerciseEquipment(result);
                if (row.instructions) {
                  setWorkoutExerciseInstructions(row.instructions);
                } else {
                  setWorkoutExerciseInstructions("");
                }

                setWorkoutExerciseEquipment(row.equipment);

                setExerciseShow(true);
                setWorkoutExerciseID(0);
                setSelectedExercise(0);
                setSelectedExerciseName("");

                /* Temporary Mapping Create */

                let tempMappingData = exerciseRelationship.filter(
                  (ex) => parseInt(ex.exercise_id) === parseInt(row.id)
                );

                setExerciseIsTemp(true);
                setExerciseTempMapping(tempMappingData);
              }}
            >
              <FontAwesomeIcon icon={faClone} />
              {/* Clone */}
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={renderTooltip("Edit Exercise")}>
            <Button
              variant="outline-primary"
              size="sm"
              className="mx-2"
              onClick={() => {
                setIsEdit(true);
                setWorkoutExercise(row.name);
                setWorkoutExerciseDesc(row.description);
                setWorkoutExerciseDiff(row.difficulty);
                setWorkoutExerciseImage(row.img_url);
                setWorkoutExerciseIsTimed(row.is_timed);
                setWorkoutExerciseReps(row.reps_per_minute.toString());
                setWorkoutExerciseCalories(row.calories_per_minute.toString());
                setWorkoutExerciseMet(row.met.toString());
                setWorkoutExerciseContentUrl(row.content_url);
                const result = equipmentOptions.filter((data) =>
                  row.equipment.includes(data.value)
                );
                setDefaultExerciseEquipment(result);
                if (row.instructions) {
                  setWorkoutExerciseInstructions(row.instructions);
                } else {
                  setWorkoutExerciseInstructions("");
                }

                setWorkoutExerciseEquipment(row.equipment);

                setExerciseShow(true);
                setWorkoutExerciseID(row.id);
                setSelectedExercise(row.id);
                setSelectedExerciseName(row.name);
                /* Temp Off */
                setExerciseIsTemp(false);
                setExerciseTempMapping([]);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
              {/* Edit */}
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={renderTooltip("Delete Exercise")}>
            <Button
              variant="outline-danger"
              size="sm"
              className="mx-2"
              onClick={() => {
                handleDelete(row.id, "workout-exercises");
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              {/* Delete */}
            </Button>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  const [dtExerciseList, setDtExerciseList] = useState([]);

  useEffect(()=>{
    if(workoutExerciseList){

      let dtList = workoutExerciseList
      .filter(
        (item) =>
          searchWExercise === "" ||
          item.name
            .toLowerCase()
            .includes(searchWExercise.toLowerCase()) ||
          item.id.includes(searchWExercise.toLowerCase())
      );      

      setDtExerciseList(dtList)
    }

  },[workoutExerciseList, searchWExercise]);

  return (
    <div>
      {isLoading ? <Preloader /> : ""}

      <Tab.Container id="left-tabs-example" defaultActiveKey="workout_type">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="workout_type">Workout Types</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="workout_category">
                  Workout Categories
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="workout_muscle">Muscle</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="workout_exercise">Exercise</Nav.Link>
              </Nav.Item>
            </Nav>

            <img
              src={require("../assets/img/workout-1.png")}
              alt=""
              className=""
              style={{ width: "100%", transform: "scaleX(-1)" }}
            />
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="workout_type">
                <div className="text-start">
                  <Row className="my-3">
                    <Col lg={6}>
                      <h5>Workout Types</h5>
                    </Col>
                    <Col lg={6}>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mx-2 float-end"
                        onClick={() => {
                          setTypeShow(true);
                          setIsEdit(false);
                          setWorkoutType("");
                          setWorkoutTypeID(0);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} /> Create
                      </Button>
                    </Col>
                  </Row>
                </div>

                {/* Modal Section */}
                <Modal show={typeShow} onHide={() => setTypeShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h5 className="mb-0">
                        {isEdit === true ? "Update" : "Create"} Workout Type
                      </h5>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Workout Type</Form.Label>
                      <Form.Control
                        type="text"
                        value={workoutType}
                        onChange={(e) => setWorkoutType(e.target.value)}
                        placeholder="Enter Workout Type"
                      />
                    </Form.Group>
                    <div className="text-end">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="mx-2"
                        onClick={() => setTypeShow(false)}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        className="mx-2"
                        onClick={() => handleSubmitType(workoutTypeID)}
                        type="submit"
                      >
                        {isEdit === true ? "Update" : "Create"}
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* End Modal Section */}

                <div>
                  <Col md={6} className="float-start mb-3">
                    <h6 className="my-3 text-start">
                      <b>Total Workout Types: {workoutTypeList?.length}</b>
                    </h6>
                  </Col>
                  <Col md={4} className="float-end">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        value={searchWType}
                        onChange={(e) => setSearchWType(e.target.value)}
                        placeholder="Search Workout Type"
                      />
                    </Form.Group>
                  </Col>
                </div>

                <div className="type-dt">
                  <DataTable
                    columns={typeColumns}
                    striped
                    data={dtTypeList}
                    pagination
                    paginationPerPage={10}
                    searchable
                    dense
                    className="typeDT"
                  />
                </div>

                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Workout Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workoutTypeList
                      .filter(
                        (item) =>
                          searchWType === "" ||
                          item.name
                            .toLowerCase()
                            .includes(searchWType.toLowerCase())
                      )
                      ?.map((obj, index) => (
                        <tr key={index}>
                          <td>{obj.id}</td>
                          <td>{obj.name}</td>
                          <td>
                            <Button
                              variant="outline-primary"
                              size="sm"
                              className="mx-2"
                              disabled={obj.is_system_generated}
                              onClick={() => {
                                setIsEdit(true);
                                setWorkoutType(obj.name);
                                setTypeShow(true);
                                setWorkoutTypeID(obj.id);
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} /> Edit
                            </Button>
                            <Button
                              variant="outline-danger"
                              size="sm"
                              className="mx-2"
                              disabled={obj.is_system_generated}
                              onClick={() => {
                                handleDelete(obj.id, "workout-types");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} /> Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table> */}
              </Tab.Pane>

              <Tab.Pane eventKey="workout_category">
                <div className="text-start">
                  <Row className="my-3">
                    <Col lg={6}>
                      <h5>Workout Categories</h5>
                    </Col>
                    <Col lg={6}>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mx-2 float-end"
                        onClick={() => {
                          setCategoryShow(true);
                          setIsEdit(false);
                          setWorkoutCategory("");
                          setWorkoutCategoryID(0);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} /> Create
                      </Button>
                    </Col>
                  </Row>
                </div>

                {/* Modal Section */}
                <Modal
                  show={categoryShow}
                  onHide={() => setCategoryShow(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h5 className="mb-0">
                        {isEdit === true ? "Update" : "Create"} Workout Category
                      </h5>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Workout Category</Form.Label>
                      <Form.Control
                        type="text"
                        value={workoutCategory}
                        onChange={(e) => setWorkoutCategory(e.target.value)}
                        placeholder="Enter Workout Category"
                      />
                    </Form.Group>
                    <div className="text-end">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="mx-2"
                        onClick={() => setCategoryShow(false)}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        className="mx-2"
                        onClick={() => handleSubmitCategory(workoutCategoryID)}
                        type="submit"
                      >
                        {isEdit === true ? "Update" : "Create"}
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* End Modal Section */}

                <div>
                  <Col md={6} className="float-start mb-3">
                    <h6 className="my-3 text-start">
                      <b>Total Workout Categories: {workoutCategoryList?.length}</b>
                    </h6>
                  </Col>
                  <Col md={4} className="float-end">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        value={searchWCategory}
                        onChange={(e) => setSearchWCategory(e.target.value)}
                        placeholder="Search Workout Category"
                      />
                    </Form.Group>
                  </Col>
                </div>

                <div className="category-dt">
                  <DataTable
                    columns={categoryColumns}
                    striped
                    data={dtCategoryList}
                    pagination
                    paginationPerPage={10}
                    searchable
                    dense
                    className="categoryDT"
                  />
                </div>

                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Worlout Category</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workoutCategoryList
                      .filter(
                        (item) =>
                          searchWCategory === "" ||
                          item.name
                            .toLowerCase()
                            .includes(searchWCategory.toLowerCase())
                      )
                      ?.map((obj, index) => (
                        <tr key={index}>
                          <td>{obj.id}</td>
                          <td>{obj.name}</td>
                          <td>
                            <Button
                              variant="outline-primary"
                              size="sm"
                              className="mx-2"
                              disabled={obj.is_system_generated}
                              onClick={() => {
                                setIsEdit(true);
                                setWorkoutCategory(obj.name);
                                setCategoryShow(true);
                                setWorkoutCategoryID(obj.id);
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} /> Edit
                            </Button>
                            <Button
                              variant="outline-danger"
                              size="sm"
                              className="mx-2"
                              disabled={obj.is_system_generated}
                              onClick={() => {
                                handleDelete(obj.id, "workout-categories");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} /> Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table> */}
              </Tab.Pane>

              <Tab.Pane eventKey="workout_muscle">
                <div className="text-start">
                  <Row className="my-3">
                    <Col lg={6}>
                      <h5>Muscle</h5>
                    </Col>
                    <Col lg={6}>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mx-2 float-end"
                        onClick={() => {
                          setMuscleShow(true);
                          setIsEdit(false);
                          setWorkoutMuscle("");
                          setWorkoutMuscleID(0);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} /> Create
                      </Button>
                    </Col>
                  </Row>
                </div>

                {/* Modal Section */}
                <Modal show={muscleShow} onHide={() => setMuscleShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h5 className="mb-0">
                        {isEdit === true ? "Update" : "Create"} Muscle
                      </h5>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Muscle</Form.Label>
                      <Form.Control
                        type="text"
                        value={workoutMuscle}
                        onChange={(e) => setWorkoutMuscle(e.target.value)}
                        placeholder="Enter Muscle"
                      />
                    </Form.Group>
                    <div className="text-end">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="mx-2"
                        onClick={() => setMuscleShow(false)}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        className="mx-2"
                        onClick={() => handleSubmitMuscle(workoutMuscleID)}
                        type="submit"
                      >
                        {isEdit === true ? "Update" : "Create"}
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* End Modal Section */}

                <div>
                  <Col md={6} className="float-start mb-3">
                    <h6 className="my-3 text-start">
                      <b>Total Muscle: {workoutMuscleList?.length}</b>
                    </h6>
                  </Col>
                  <Col md={4} className="float-end">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        value={searchWMuscle}
                        onChange={(e) => setSearchWMuscle(e.target.value)}
                        placeholder="Search Workout Muscle"
                      />
                    </Form.Group>
                  </Col>
                </div>

                <div className="muscle-dt">
                  <DataTable
                    columns={muscleColumns}
                    striped
                    data={dtMuscleList}
                    pagination
                    paginationPerPage={10}
                    searchable
                    dense
                    className="muscleDT"
                  />
                </div>

                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Muscle</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workoutMuscleList
                      .filter(
                        (item) =>
                          searchWMuscle === "" ||
                          item.name
                            .toLowerCase()
                            .includes(searchWMuscle.toLowerCase())
                      )
                      ?.map((obj, index) => (
                        <tr key={index}>
                          <td>{obj.id}</td>
                          <td>{obj.name}</td>
                          <td>
                            <Button
                              variant="outline-primary"
                              size="sm"
                              className="mx-2"
                              disabled={obj.is_system_generated}
                              onClick={() => {
                                setIsEdit(true);
                                setWorkoutMuscle(obj.name);
                                setMuscleShow(true);
                                setWorkoutMuscleID(obj.id);
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} /> Edit
                            </Button>
                            <Button
                              variant="outline-danger"
                              size="sm"
                              className="mx-2"
                              disabled={obj.is_system_generated}
                              onClick={() => {
                                handleDelete(obj.id, "workout-muscles");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} /> Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table> */}
              </Tab.Pane>

              <Tab.Pane eventKey="workout_exercise">
                <div className="text-start">
                  <Row className="my-3">
                    <Col lg={6}>
                      <h5>
                        <FontAwesomeIcon icon={faDumbbell} /> Exercise
                      </h5>
                    </Col>
                    <Col lg={6}>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mx-2 float-end"
                        onClick={() => {
                          setExerciseShow(true);
                          setIsEdit(false);
                          setWorkoutExercise("");
                          setWorkoutExerciseID(0);
                          handleReset();

                          setExerciseIsTemp(false);
                          setExerciseTempMapping([]);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} /> Create
                      </Button>
                      <Button
                        variant="outline-dark"
                        size="sm"
                        className="mx-2 float-end"
                        onClick={handleExport}
                      >
                        <FontAwesomeIcon icon={faUpload} /> Export
                      </Button>
                      <Button
                        variant="outline-success"
                        size="sm"
                        className="mx-2 float-end"
                        onClick={handleImport}
                      >
                        <FontAwesomeIcon icon={faDownload} /> Import
                      </Button>
                    </Col>
                  </Row>
                </div>

                <ImportModal
                  show={importModalOpen}
                  onHide={handleModalClose}
                  onFileUpload={handleFileUpload}
                />

                <div>
                  <Col md={6} className="float-start mb-3">
                    <h6 className="my-3 text-start">
                      <b>Total Exercises: {workoutExerciseList?.length}</b>
                    </h6>
                  </Col>
                  <Col md={4} className="float-end mb-3">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Control
                        type="text"
                        value={searchWExercise}
                        onChange={(e) => setSearchWExercise(e.target.value)}
                        placeholder="Search Workout Exercise"
                      />
                    </Form.Group>
                  </Col>
                </div>

                <div className="exercise-dt">
                  <DataTable
                    columns={exerciseColumns}
                    striped
                    data={dtExerciseList}
                    pagination
                    paginationPerPage={10}
                    searchable
                    dense
                    className="exerciseDT"
                  />
                </div>

                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th onClick={() => sortTable("id")}># </th>
                      <th onClick={() => sortTable("name")}>Name</th>
                      <th onClick={() => sortTable("difficulty")}>
                        Difficulty
                      </th>
                      <th onClick={() => sortTable("equipment")}>Equipment</th>
                      <th onClick={() => sortTable("muscle_length")}>Mapped</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workoutExerciseList
                      .filter(
                        (item) =>
                          searchWExercise === "" ||
                          item.name
                            .toLowerCase()
                            .includes(searchWExercise.toLowerCase()) ||
                          item.id.includes(searchWExercise.toLowerCase())
                      )
                      ?.map((obj, index) => (
                        <tr key={index}>
                          <td>{parseInt(obj.id)}</td>
                          <td>{obj.name}</td>
                          <td>{obj.difficulty}</td>
                          <td className="text-capitalize">
                            {obj.equipment.toString()}
                          </td>
                          <td>{obj.muscle_length}</td>
                          <td>
                            <Button
                              variant="outline-success"
                              size="sm"
                              className="mx-2"
                              onClick={() => {
                                setIsEdit(false);
                                setWorkoutExercise("Copy of " + obj.name);
                                setWorkoutExerciseDesc(obj.description);
                                setWorkoutExerciseDiff(obj.difficulty);
                                setWorkoutExerciseImage(obj.img_url);
                                setWorkoutExerciseIsTimed(obj.is_timed);
                                setWorkoutExerciseReps(
                                  obj.reps_per_minute.toString()
                                );
                                setWorkoutExerciseCalories(
                                  obj.calories_per_minute.toString()
                                );
                                setWorkoutExerciseMet(obj.met.toString());
                                setWorkoutExerciseContentUrl(obj.content_url);
                                const result = equipmentOptions.filter((data) =>
                                  obj.equipment.includes(data.value)
                                );
                                setDefaultExerciseEquipment(result);
                                if (obj.instructions) {
                                  setWorkoutExerciseInstructions(
                                    obj.instructions
                                  );
                                } else {
                                  setWorkoutExerciseInstructions("");
                                }

                                setWorkoutExerciseEquipment(obj.equipment);

                                setExerciseShow(true);
                                setWorkoutExerciseID(0);
                                setSelectedExercise(0);
                                setSelectedExerciseName("");

                                let tempMappingData =
                                  exerciseRelationship.filter(
                                    (ex) =>
                                      parseInt(ex.exercise_id) ===
                                      parseInt(obj.id)
                                  );

                                setExerciseIsTemp(true);
                                setExerciseTempMapping(tempMappingData);
                              }}
                            >
                              <FontAwesomeIcon icon={faClone} /> Clone
                            </Button>

                            <Button
                              variant="outline-primary"
                              size="sm"
                              className="mx-2"
                              onClick={() => {
                                setIsEdit(true);
                                setWorkoutExercise(obj.name);
                                setWorkoutExerciseDesc(obj.description);
                                setWorkoutExerciseDiff(obj.difficulty);
                                setWorkoutExerciseImage(obj.img_url);
                                setWorkoutExerciseIsTimed(obj.is_timed);
                                setWorkoutExerciseReps(
                                  obj.reps_per_minute.toString()
                                );
                                setWorkoutExerciseCalories(
                                  obj.calories_per_minute.toString()
                                );
                                setWorkoutExerciseMet(obj.met.toString());
                                setWorkoutExerciseContentUrl(obj.content_url);
                                const result = equipmentOptions.filter((data) =>
                                  obj.equipment.includes(data.value)
                                );
                                setDefaultExerciseEquipment(result);
                                if (obj.instructions) {
                                  setWorkoutExerciseInstructions(
                                    obj.instructions
                                  );
                                } else {
                                  setWorkoutExerciseInstructions("");
                                }

                                setWorkoutExerciseEquipment(obj.equipment);

                                setExerciseShow(true);
                                setWorkoutExerciseID(obj.id);
                                setSelectedExercise(obj.id);
                                setSelectedExerciseName(obj.name);
                               
                                setExerciseIsTemp(false);
                                setExerciseTempMapping([]);
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} /> Edit
                            </Button>

                            <Button
                              variant="outline-danger"
                              size="sm"
                              className="mx-2"
                              onClick={() => {
                                handleDelete(obj.id, "workout-exercises");
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} /> Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table> */}

                {/* Modal Section */}
                <Modal
                  size="lg"
                  show={exerciseShow}
                  onHide={() => setExerciseShow(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h5 className="mb-0">
                        {isEdit === true ? "Update" : "Create"} Exercise
                      </h5>
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    {/* Add/Edit */}
                    <Row>
                      <Col lg={8} md={8}>
                        <Row>
                          <Col lg={10}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Exercise Name</Form.Label>

                              <Form.Control
                                type="text"
                                value={workoutExercise}
                                onChange={(e) =>
                                  setWorkoutExercise(e.target.value)
                                }
                                placeholder="Enter Exercise Name"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={2}>
                            <Form>
                              <Form.Label>Is Timed</Form.Label>
                              <Form.Check // prettier-ignore
                                className="form-switch"
                                type="switch"
                                id="is_timed"
                                label=""
                                checked={workoutExerciseIsTimed}
                                onChange={(e) => {
                                  setWorkoutExerciseIsTimed(e.target.checked);
                                }}
                              />
                            </Form>
                          </Col>

                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Description</Form.Label>

                              <Form.Control
                                as="textarea"
                                rows={3}
                                value={workoutExerciseDesc}
                                onChange={(e) =>
                                  setWorkoutExerciseDesc(e.target.value)
                                }
                                placeholder="Enter Exercise Description"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Equipment</Form.Label>
                              <Select
                                isMulti
                                name="category"
                                options={equipmentOptions}
                                value={defaultExerciseEquipment}
                                onChange={(e) => {
                                  setDefaultExerciseEquipment(e);
                                  const tempArry =
                                    e?.map((obj) => obj.value) || [];
                                  setWorkoutExerciseEquipment(tempArry);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={4} md={4}>
                        <div className="exercise-image">
                          {workoutExerciseImage ? (
                            <img src={workoutExerciseImage} alt="Uploaded" />
                          ) : (
                            <img src={defaultExerciseImage} alt="Uploaded" />
                          )}
                        </div>
                        <Col lg={12}>
                          <Form.Group controlId="formFile" className="mb-3">
                            {/* <Form.Label>Exercise Image</Form.Label> */}
                            <Form.Control
                              type="file"
                              className="mt-2"
                              onChange={handleImageUpload}
                            />
                          </Form.Group>
                        </Col>
                      </Col>

                      <Col lg={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Reps/Min</Form.Label>

                          <Form.Control
                            type="number"
                            value={workoutExerciseReps}
                            onChange={(e) =>
                              setWorkoutExerciseReps(e.target.value)
                            }
                            placeholder="Enter Reps/Min"
                          />
                        </Form.Group>
                      </Col>

                      {/* <Col lg={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Calories/Min</Form.Label>

                          <Form.Control
                            type="number"
                            value={workoutExerciseCalories}
                            onChange={(e) =>
                              setWorkoutExerciseCalories(e.target.value)
                            }
                            placeholder="Enter Calories/Min"
                          />
                        </Form.Group>
                      </Col> */}

                      <Col lg={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Met</Form.Label>

                          <Form.Control
                            type="number"
                            value={workoutExerciseMet}
                            onChange={(e) =>
                              setWorkoutExerciseMet(e.target.value)
                            }
                            placeholder="Enter Met"
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Difficulty</Form.Label>

                          <Form.Select
                            aria-label="difficulty"
                            as="select"
                            value={workoutExerciseDiff}
                            onChange={(e) => {
                              setWorkoutExerciseDiff(e.target.value);
                            }}
                          >
                            <option disabled value="">
                              Select Exercise Difficulty
                            </option>
                            <option value="easy">Easy</option>
                            <option value="medium">Medium</option>
                            <option value="hard">Hard</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col lg={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Content URL</Form.Label>

                          <Form.Control
                            type="text"
                            value={workoutExerciseContentUrl}
                            onChange={(e) =>
                              setWorkoutExerciseContentUrl(e.target.value)
                            }
                            placeholder="Enter Content URL"
                          />
                        </Form.Group>
                      </Col>

                      <Row>
                        <Col lg={6}>
                          <Form.Label>Exercise Instructions</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={10}
                            className="Exercise-Instructions"
                            value={workoutExerciseInstructions}
                            onChange={handleExerciseInstructionsChange}
                            placeholder="Enter Exercise Instrctions"
                          />
                        </Col>
                        <Col lg={6}>
                          <div>
                            <Form.Label>
                              <FontAwesomeIcon icon={faList} /> Preview
                            </Form.Label>
                            <div className="preview-instructions">
                              <ul className="Instructions-list">
                                {splitByNewline()?.map((line, index) => (
                                  <Fragment key={index}>
                                    {line !== "" ? <li>{line}</li> : <></>}
                                  </Fragment>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="text-end mt-2">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="mx-2"
                          onClick={() => setExerciseShow(false)}
                        >
                          Close
                        </Button>

                        <Button
                          variant="primary"
                          size="sm"
                          className="mx-2"
                          onClick={() => {
                            handleSubmitExercise(workoutExerciseID);
                          }}
                          type="submit"
                        >
                          {isEdit === true ? "Update" : "Create"}
                        </Button>
                      </div>
                    </Row>

                    {/* Temp Mapping */}

                    {isEdit &&
                    exerciseIsTemp &&
                    exerciseTempMapping.length > 0 ? (
                      <>
                        <div>Mapping Suggestions</div>
                        <Table striped bordered hover className="my-2">
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Category</th>
                              <th>Muscle</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {exerciseTempMapping?.map((obj, index) => (
                              <tr key={index}>
                                <td>{obj.workoutType_name}</td>
                                <td>{obj.workoutCategory_name}</td>
                                <td>{obj.workoutMuscle_name}</td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    size="sm"
                                    className="mx-2 mt-2"
                                    onClick={() => {
                                      let typeID = obj.workoutType_id;
                                      let categoryID = obj.workoutCategory_id;
                                      let muscleID = obj.workoutMuscle_id;
                                      let exerciseID = workoutExerciseID;

                                      cloneRelationship(
                                        typeID,
                                        categoryID,
                                        muscleID,
                                        exerciseID,
                                        obj.id
                                      );
                                    }}
                                  >
                                    Clone Mapping
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      ""
                    )}

                    {/* MApping Section */}
                    {isEdit === true ? (
                      <div
                        className="border-primary p-2 mt-3"
                        style={{ border: "2px solid" }}
                      >
                        <h5 className="mb-3 bg-info py-2 px-1">
                          <FontAwesomeIcon icon={faRunning} />{" "}
                          {selectedExerciseName} Exercise - Mapping
                        </h5>
                        <Row>
                          <Col md={10}>
                            <Row>
                              <Col md={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Workout Type </Form.Label>

                                  <Form.Select
                                    aria-label="workout_type"
                                    as="select"
                                    value={selectedType}
                                    onChange={(e) => {
                                      setSelectedType(e.target.value);
                                      setSelectedTypeName(
                                        e.target.selectedOptions[0].text
                                      );
                                    }}
                                  >
                                    <option disabled value="">
                                      Select Type
                                    </option>
                                    {typeObj?.map((obj, index) => (
                                      <option key={index} value={obj.id}>
                                        {obj.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Workout Category </Form.Label>

                                  <Form.Select
                                    aria-label="workout_category"
                                    as="select"
                                    value={selectedCategory}
                                    onChange={(e) => {
                                      setSelectedCategory(e.target.value);
                                      setSelectedCategoryName(
                                        e.target.selectedOptions[0].text
                                      );
                                    }}
                                  >
                                    <option disabled value="">
                                      Select Category
                                    </option>
                                    {categoryObj?.map((obj, index) => (
                                      <option key={index} value={obj.id}>
                                        {obj.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Muscle </Form.Label>

                                  <Form.Select
                                    aria-label="workout_muscle"
                                    as="select"
                                    value={selectedMuscle}
                                    onChange={(e) => {
                                      setSelectedMuscle(e.target.value);
                                      setSelectedMuscleName(
                                        e.target.selectedOptions[0].text
                                      );
                                    }}
                                  >
                                    <option disabled value="">
                                      Select Muscle
                                    </option>
                                    {muscleObj?.map((obj, index) => (
                                      <option key={index} value={obj.id}>
                                        {obj.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={2}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <br />
                              <Button
                                variant="outline-primary"
                                size="sm"
                                className="mx-2 mt-2"
                                onClick={() => {
                                  addRelationship();
                                }}
                              >
                                <FontAwesomeIcon icon={faAdd} /> Add
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>

                        {exerciseRelationship.filter(
                          (ex) =>
                            parseInt(ex.exercise_id) ===
                            parseInt(selectedExercise)
                        )?.length ? (
                          <Table striped bordered hover className="mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Category</th>
                                <th>Muscle</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {exerciseRelationship
                                .filter(
                                  (ex) =>
                                    parseInt(ex.workoutExercise_id) ===
                                    parseInt(selectedExercise)
                                )
                                ?.map((obj, index) => (
                                  <tr key={index}>
                                    <td>{obj.id}</td>
                                    <td>{obj.workoutType_name}</td>
                                    <td>{obj.workoutCategory_name}</td>
                                    <td>{obj.workoutMuscle_name}</td>
                                    <td>
                                      <Button
                                        variant="outline-danger"
                                        size="sm"
                                        className="mx-2"
                                        onClick={() => {
                                          // setSelectedType(obj.workoutType_id);
                                          // setSelectedCategory(obj.workoutCategory_id);
                                          // setSelectedMuscle(obj.workoutMuscle_id);
                                          // setSelectedExercise(obj.workoutExercise_id);

                                          removeRelationship(
                                            obj.id,
                                            obj.wcategory_muscle_id,
                                            obj.workoutType_id,
                                            obj.workoutCategory_id,
                                            obj.workoutMuscle_id,
                                            obj.workoutExercise_id
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />{" "}
                                        Remove
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        ) : (
                          <div className="text-center">
                            <Fragment>No Relationship Found</Fragment>
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </Modal.Body>
                </Modal>
                {/* End Modal Section */}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default CreateEntity;
