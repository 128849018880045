import API from "../components/api/API";

export const getWorkoutTypeAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/workout-types")
      .then((response) => {
        resolve({ status: "success", data: response.data.data.aic_wtype });
      })
      .catch((error) => {
        resolve({ status: "error", msg: error.response.status });
      });
  });

export const getWorkoutCatgeoryAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/workout-categories")
      .then((response) => {
        resolve({ status: "success", data: response.data.data.aic_wcategory });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });

export const getWorkoutMuscleAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/workout-muscles")
      .then((response) => {
        resolve({ status: "success", data: response.data.data.aic_muscle });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });

export const getWorkoutExerciseAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/workout-exercises")
      .then((response) => {
        resolve({ status: "success", data: response.data.data.aic_wexercise });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });

export const getWorkoutsAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/workouts")
      .then((response) => {
        resolve({ status: "success", data: response.data.data.aic_workout });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });

export const getWorkoutTypeWorkoutCategoryAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/workout-type-workout-category")
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data.aic_wtype_wcategory,
        });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });

export const getWorkoutCategoryMuscleAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/workout-category-muscle")
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data.aic_wcategory_muscle,
        });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });

export const getMuscleExerciseAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/muscle-exercise")
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data.aic_exercise_muscle,
        });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });

export const getWorkoutExerciseMapAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/workout-exercise")
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data.aic_workout_exercise,
        });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });

export const getExerciseDetailsAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/exercise-details")
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data.aic_exercise_details,
        });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });

export const getActivityTemplateAPI = () =>
  new Promise((resolve, reject) => {
    API.get("/activity-template")
      .then((response) => {
        resolve({
          status: "success",
          data: response.data.data.aic_activity_template,
        });
      })
      .catch((error) => {
        resolve(error.response.status);
      });
  });
