import { createContext, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  getWorkoutCatgeoryAPI,
  getWorkoutExerciseAPI,
  getWorkoutMuscleAPI,
  getWorkoutTypeAPI,
  getWorkoutTypeWorkoutCategoryAPI,
  getWorkoutCategoryMuscleAPI,
  getMuscleExerciseAPI,
  getWorkoutsAPI,
  getWorkoutExerciseMapAPI,
  getExerciseDetailsAPI,
  getActivityTemplateAPI
} from "../common/getWorkoutAPI";

const DataContext = createContext({});
// const cookies= new Cookies()

export const DataProvider = ({ children }) => {
  const [workoutTypeList, setWorkoutTypeList] = useState([]);
  const [workoutCategoryList, setWorkoutCategoryList] = useState([]);
  const [workoutMuscleList, setWorkoutMuscleList] = useState([]);
  const [workoutExerciseList, setWorkoutExerciseList] = useState([]);

  const [workoutsList, setWorkoutsList] = useState([]);
  const [workoutsAIList, setWorkoutsAIList] = useState([]);

  const [mapTypeCategoryList, setMapTypeCategoryList] = useState([]);
  const [mapCategoryMuscleList, setMapCategoryMuscleList] = useState([]);
  const [mapMuscleExerciseList, setMapMuscleExerciseList] = useState([]);
  const [mapWorkoutExerciseList, setMapWorkoutExerciseList] = useState([]);
  const [exerciseDetailList, setExerciseDetailList] = useState([]);
  const [activityTemplateList, setActivityTemplateList] = useState([]);

  const [workoutExerciseObj, setWorkoutExerciseObj] = useState([]);
  
  const [accessToken,setAccessToken]= useState("");
  const [isPreLoading, setIsPreLoading] = useState(true);
  
  useEffect(() => {

    return async () => {
      const typeResponse = await getWorkoutTypeAPI();
      const categoryResponse = await getWorkoutCatgeoryAPI();
      const muscleResponse = await getWorkoutMuscleAPI();
      const exerciseResponse = await getWorkoutExerciseAPI();
     
      const mapTypeCategoryResponse = await getWorkoutTypeWorkoutCategoryAPI();
      const mapCategoryMuscleResponse = await getWorkoutCategoryMuscleAPI();
      const mapMuscleExerciseResponse = await getMuscleExerciseAPI();
      const mapWorkoutExerciseResponse = await getWorkoutExerciseMapAPI();
      const exerciseDetailsResponse = await getExerciseDetailsAPI();
      const activityTemplateResponse = await getActivityTemplateAPI();

      setTimeout(() => {
        setIsPreLoading(false)
      }, 1000);

      const workoutResponse = await getWorkoutsAPI();

      if (typeResponse.status === "success") {
        setWorkoutTypeList(typeResponse.data);
      }

      if (categoryResponse.status === "success") {
        setWorkoutCategoryList(categoryResponse.data);
      }

      if (muscleResponse.status === "success") {
        setWorkoutMuscleList(muscleResponse.data);
      }

      if (exerciseResponse.status === "success") {
        setWorkoutExerciseList(exerciseResponse.data);
      }

      if (workoutResponse.status === "success") {
        
        let workoutResponseData = workoutResponse.data;        
        const manualWorkoutDataFilter = workoutResponseData.filter(obj => obj.is_manual);
        const aiWorkoutDataFilter = workoutResponseData.filter(obj => !obj.is_manual);
        
        setWorkoutsList(manualWorkoutDataFilter);
        
        const updatedWorkoutList =
          mapMuscleExerciseResponse.status === "success"
            ? getExerciseObj(aiWorkoutDataFilter)
            : aiWorkoutDataFilter;

        setWorkoutsAIList(updatedWorkoutList);
      

      }

      if (mapTypeCategoryResponse.status === "success") {
        setMapTypeCategoryList(mapTypeCategoryResponse.data);
      }

      if (mapCategoryMuscleResponse.status === "success") {
        setMapCategoryMuscleList(mapCategoryMuscleResponse.data);
      }

      if (mapMuscleExerciseResponse.status === "success") {
        let mapMuscleExerciseResponseData = mapMuscleExerciseResponse.data;

        if (mapMuscleExerciseResponseData) {
          const updatedMapMuscleExerciseList =
            mapMuscleExerciseResponseData.map((obj) => {

              const {
                aic_exercise,
                aic_wcategory_muscle: {
                  id: wmuscle_wcategory_id,
                  aic_muscle: { id: workoutMuscle_id, name: workoutMuscle_name },
                  aic_wtype_wcategory: {
                    id: wtype_wcategory_id,
                    aic_wcategory: { id: workoutCategory_id, name: workoutCategory_name },
                    aic_wtype: { id: workoutType_id, name: workoutType_name },
                  },
                },
              } = obj;

              return {
                ...obj,
                workoutExercise_name: aic_exercise.name,
                workoutExercise_id: aic_exercise.id,
                wmuscle_wcategory_id,
                workoutMuscle_id,
                workoutMuscle_name,
                wtype_wcategory_id,
                workoutCategory_id,
                workoutCategory_name,
                workoutType_id,
                workoutType_name,
              };
              // return {
              //   ...obj,

              //   workoutExercise_name: obj.aic_exercise.name,
              //   workoutExercise_id: obj.aic_exercise.id,

              //   wmuscle_wcategory_id: obj.aic_wcategory_muscle.id,
              //   workoutMuscle_id: obj.aic_wcategory_muscle?.aic_muscle?.id,
              //   workoutMuscle_name: obj.aic_wcategory_muscle?.aic_muscle?.name,

              //   wtype_wcategory_id:
              //     obj.aic_wcategory_muscle.aic_wtype_wcategory.id,
              //   workoutCategory_id:
              //     obj.aic_wcategory_muscle.aic_wtype_wcategory.aic_wcategory.id,
              //   workoutCategory_name:
              //     obj.aic_wcategory_muscle.aic_wtype_wcategory.aic_wcategory
              //       .name,

              //   workoutType_id:
              //     obj.aic_wcategory_muscle.aic_wtype_wcategory.aic_wtype.id,
              //   workoutType_name:
              //     obj.aic_wcategory_muscle.aic_wtype_wcategory.aic_wtype.name,
              // };
            });

          setMapMuscleExerciseList(updatedMapMuscleExerciseList);
        }
      }

      if (mapWorkoutExerciseResponse.status === "success") {
        setMapWorkoutExerciseList(mapWorkoutExerciseResponse.data);
      }

      if (exerciseDetailsResponse.status === "success") {
        setExerciseDetailList(exerciseDetailsResponse.data);
      }

      if (activityTemplateResponse.status === "success") {
        setActivityTemplateList(activityTemplateResponse.data);
      }

      
    };
    
  }, [accessToken]);

  useEffect(() => {
    if((new Cookies()).get('access_token')){
      setAccessToken((new Cookies()).get('access_token'))
    }
  },[]);

  //Alter Workout List mapping
  useEffect(() => {
    
    if (workoutsList.length) {

      let alterWorkoutList = getExerciseObj(workoutsList)

      setWorkoutsList(alterWorkoutList);
    }
   
  }, [mapWorkoutExerciseList, accessToken]); //exerciseDetailList

  const getExerciseObj = (workoutObj) =>{

    return workoutObj.map((obj) => {
      let thisWorkoutID = obj.id;

      if (mapWorkoutExerciseList.length) {
        let exerciseList = mapWorkoutExerciseList
          .filter((Obj) => parseInt(Obj.workout_id) === parseInt(thisWorkoutID))
          .sort((a, b) => a.sequence - b.sequence);

        obj["exercise_obj"] = exerciseList;
        return obj;
      } else {
        return { ...obj, exercise_obj: [] };
      }
    });
  }
  
  return (
    <DataContext.Provider
      value={{
        workoutTypeList,
        setWorkoutTypeList,
        workoutCategoryList,
        setWorkoutCategoryList,
        workoutMuscleList,
        setWorkoutMuscleList,
        workoutExerciseList,
        setWorkoutExerciseList,
        workoutsList,
        setWorkoutsList,
        workoutsAIList,
        setWorkoutsAIList,
        mapTypeCategoryList,
        setMapTypeCategoryList,
        mapCategoryMuscleList,
        setMapCategoryMuscleList,
        mapMuscleExerciseList,
        setMapMuscleExerciseList,
        mapWorkoutExerciseList,
        setMapWorkoutExerciseList,
        exerciseDetailList,
        setExerciseDetailList,
        activityTemplateList,
        setActivityTemplateList,
        workoutExerciseObj, 
        setWorkoutExerciseObj,
        accessToken,
        setAccessToken,
        isPreLoading
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
