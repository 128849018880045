import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Button,
  Row,
  Container,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import DataContext from "../context/DataContext";
import { APIv1 } from "./api/API";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = ({ setSigned }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { accessToken, setAccessToken } = useContext(DataContext);

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const validateEmail = (inputText) => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleSubmit = async () => {
    if (validateEmail(email)) {
      APIv1.post("/user/login", {
        login_type: "email",
        email,
        password,
        app_version: "1.0.0",
        mobile_type: "android",
        mobile_details: {
          device: "Test Device",
          model_no: "8T",
          manufacturer: "OnePlus",
          os_build_version: "test_version",
          mobile_unique_id: "123123123",
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            cookies.set("access_token", response.data.data.access_token, {
              path: "/",
              maxAge: 604800,
            });

            setAccessToken(response.data.data.access_token);
            setSigned(true);
            navigate("/");
          }
        })
        .catch((error) => {
          Swal.fire(error.message, "", "warning");
        });
    }
  };
  return (
    <div>
      <Row className="vh-100 d-flex justify-content-center align-items-center m-0">
        <Col md={12} className="p-0">
          <Card className="shadow fitness-login vh-100">
            <Card.Body className="d-flex align-items-center">
              <Col md={3} className="login-section ">
                <div className="mb-3 mt-md-4">
                  <img
                    src={require("../assets/img/smitch-logo.png")}
                    alt=""
                    className="fitness-logo"
                  />
                  <h4 className="fw-bold mb-2 text-uppercase ">
                    Fitness Coach Login
                  </h4>
                  <p className="mx-2"></p>
                  <div className="mb-3">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Email address
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          onChange={(e) => handleChange(e, setEmail)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            type={passwordType}
                            placeholder="Password"
                            onChange={(e) => handleChange(e, setPassword)}
                            onKeyUp={(event) => {
                              if (event.key === "Enter") {
                                handleSubmit();
                              }
                            }}
                          />
                          <InputGroup.Text
                            onClick={togglePassword}
                            style={{ cursor: "pointer" }}
                          >
                            {passwordType === "password" ? (
                              <FontAwesomeIcon icon={faEye} />
                            ) : (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      ></Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" onClick={handleSubmit}>
                          Login
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Login;
