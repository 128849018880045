import update from "immutability-helper";
import { useCallback, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useContext, useRef } from "react";
import API from "./api/API";
import { Accordion, Badge, Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import {
  faSave,
  faBars,
  faTrash,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataContext from "../context/DataContext";
import Select from "react-select";
import Swal from "sweetalert2";
import preloaderGif from "../assets/img/preloader.gif";

const style = {
  marginBottom: ".5rem",
  cursor: "move",
};

const DragAndDrop = ({
  id,
  exercise_obj,
  index,
  moveCard,
  workoutID,
  handleRemoveExercise,
}) => {
  const { mapMuscleExerciseList, exerciseDetailList, setExerciseDetailList, workoutExerciseObj, setWorkoutExerciseObj } =
    useContext(DataContext);


  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const [defaultExerciseType, setDefaultExerciseType] = useState("");
  const [exerciseType, setExerciseType] = useState("");
  const [exerciseTypeName, setExerciseTypeName] = useState("");
  const [exerciseReps, setExerciseReps] = useState("0");
  const [exerciseDuration, setExerciseDuration] = useState("");
  const [exerciseWeight, setExerciseWeight] = useState("");
  const [exerciseSets, setExerciseSets] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const Preloader = () => {
    return (
      <div className="preloader-section">
        <img src={preloaderGif} alt="Loading..." />
      </div>
    );
  };

  //Add Exercise Sets
  const handleAddExerciseSets = (workoutID, exerciseID, setsSequence) => {

    setIsLoading(true);

    API.post("/exercise-details", {
      workout_id: workoutID,
      exercise_id: exerciseID,
      wtype_id: exerciseType,
      reps: exerciseReps,
      weight: exerciseWeight,
      duration: exerciseDuration !== "" ? exerciseDuration : "0",
      //sets: exerciseSets.toString(),
      sets: exerciseSets,
      sequence: exerciseSets.toString(),
    })
      .then((response) => {
        let responseData = response.data.data.aic_exercise_detail;

        var wTypeData = {
          aic_wtype: {
            name: exerciseTypeName,
          },
        };
        responseData = { ...responseData, ...wTypeData };

        const updateExerciseObj = workoutExerciseObj.map((exerciseObj) => {
          if (exerciseObj.exercise_id === exerciseID) {
            if (!exerciseObj.aic_exercise) {
              exerciseObj.aic_exercise = {};
            }
            if (!exerciseObj.aic_exercise.aic_exercise_details) {
              exerciseObj.aic_exercise.aic_exercise_details = [];
            }
            exerciseObj.aic_exercise.aic_exercise_details.push(responseData);
            return exerciseObj;
          } else {
            return exerciseObj;
          }
        });

        setWorkoutExerciseObj(updateExerciseObj);

        setExerciseDetailList([...exerciseDetailList, responseData]);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Mapped Successfully",
          showConfirmButton: false,
          timer: 800,
        });

        setExerciseType("");
        setExerciseTypeName("")
        setDefaultExerciseType("");
        setExerciseReps("0");
        setExerciseDuration("");
        setExerciseWeight("");
        setExerciseSets("");

        setIsLoading(false);
      })
      .catch((error) => {
        Swal.fire(error.response.data.message, "", "warning");
      });
  };

  //Remove Sets to Exercise
  const handleRemoveExerciseSets = (exerciseSetsID, exerciseID) => {
    API.delete(`/exercise-details/${exerciseSetsID}`).then((response) => {
         
      const updateExerciseObj = workoutExerciseObj.map((exerciseObj) => {
        if (exerciseObj.exercise_id === exerciseID) {
          let aicExerciseDetails = exerciseObj.aic_exercise.aic_exercise_details;
          const updateDetailObj = aicExerciseDetails.filter(
            (obj) => obj.id !== exerciseSetsID
          );
          return { ...exerciseObj, aic_exercise: { ...exerciseObj.aic_exercise, aic_exercise_details: updateDetailObj } };
        }
        return exerciseObj;
      });
      

      setWorkoutExerciseObj(updateExerciseObj);
    
      let removeExerciseDetailList = workoutExerciseObj.filter(
        (Obj) => parseInt(Obj.id) !== parseInt(exerciseSetsID)
      );


      setExerciseDetailList(removeExerciseDetailList);

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Remove Successfully",
        showConfirmButton: false,
        timer: 800,
      });
    });
  };
  
  //Get Workout Types Related to Exercise
  const getExerciseWorkoutTypes = (exerciseID) => {
    const selectedExerciseFilter = mapMuscleExerciseList
      .filter((Obj) => parseInt(Obj.exercise_id) === parseInt(exerciseID));
  
    const uniqueExercises = [...new Set(selectedExerciseFilter.map(obj => obj.workoutType_id))];
  
    return uniqueExercises.map(id => {
      const obj = selectedExerciseFilter.find(item => item.workoutType_id === id);
      return {
        value: obj.workoutType_id,
        label: obj.workoutType_name,
      };
    });
  }

  const getWorkoutTypeMappedNames = (exerciseID) =>{
    const workoutNames = getExerciseWorkoutTypes(exerciseID);
    return (workoutNames.map(item => item.label)).toString()
  }


  return (
    <>
      {isLoading && <Preloader />}
      <Accordion.Item
        eventKey={handlerId}
        ref={ref}
        style={{ ...style, opacity }}
        data-handler-id={handlerId}
      >
        <Accordion.Header >
          <div className="w-100 d-flex align-items-center justify-content-between">
            <span className="my-1">
              <FontAwesomeIcon icon={faBars} />
              &nbsp; <b>{exercise_obj.aic_exercise?.name}</b>
              <Badge
                bg={
                  exercise_obj.aic_exercise?.difficulty === "easy"
                    ? "success"
                    : exercise_obj.aic_exercise?.difficulty === "medium"
                    ? "primary"
                    : "danger"
                }
                style={{ textTransform: "capitalize", fontStyle: "normal" }}
                className="mx-2"
              >
                {exercise_obj.aic_exercise?.difficulty}
              </Badge>
              <span className="text-capitalize mx-2">[ {getWorkoutTypeMappedNames(exercise_obj?.aic_exercise?.id)} ]</span> 
              <span className="text-danger">
                {exercise_obj?.aic_exercise?.aic_exercise_details?.length
                  ? ""
                  : "No Sets Added"}
              </span>
            </span>
            <a
              className=" mx-2 btn btn-outline-danger btn-sm"
              onClick={() => {

                Swal.fire({
                  title: "Do you want to Delete?",
                  showCancelButton: true,
                  confirmButtonText: "Delete",
                  denyButtonText: `Don't Save`,
                }).then((result) => {
                  
                  if (result.isConfirmed) {                    
                    if (exercise_obj?.id) {
                      handleRemoveExercise(
                        exercise_obj.id,
                        workoutID,
                        exercise_obj.aic_exercise?.id,
                        workoutExerciseObj
                      );
                    }
                  } else if (result.isDenied) {
                  }
               
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Remove Exercise
            </a>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {/* Exercise Detail/Sets Section */}
          <div>
            <Row>
              <Col lg={10}>
                <Row>
                  <Col md={4}>
                    <Form.Label>Workout Type</Form.Label>

                    <Select
                      className="mb-3 text-start"
                      name="workout_types"
                      options={getExerciseWorkoutTypes(
                        exercise_obj.aic_exercise?.id
                      )}
                      value={defaultExerciseType}
                      placeholder="Workout Type"
                      onChange={(e) => {
                        setExerciseTypeName(e.label);
                        setExerciseType(e.value);
                        setDefaultExerciseType(e);
                      }}
                    />
                  </Col>

                  <Col
                    lg={4}
                    className={!exercise_obj.aic_exercise?.is_timed ? "hide" : ""}
                  >
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Duration</Form.Label>
                      <InputGroup className="">
                        <Form.Control
                          type="number"
                          value={exerciseDuration}
                          onChange={(e) => {
                            setExerciseDuration(e.target.value);
                          }}
                          placeholder="Duration"
                        />
                        <InputGroup.Text>Secs</InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>

                  <Col
                    lg={4}
                    className={!exercise_obj.aic_exercise?.is_timed ? "" : "hide"}
                  >
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Reps</Form.Label>

                      <Form.Control
                        type="number"
                        value={exerciseReps}
                        onChange={(e) => {
                          setExerciseReps(e.target.value);
                        }}
                        placeholder="Reps"
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={2}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Weight</Form.Label>

                      <Form.Control
                        type="number"
                        value={exerciseWeight}
                        onChange={(e) => {
                          setExerciseWeight(e.target.value);
                        }}
                        placeholder="0.0kg"
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={2}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Sets</Form.Label>

                      <Form.Control
                        type="number"
                        value={exerciseSets}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === '' || parseFloat(inputValue) > 0) {
                            setExerciseSets(inputValue);
                          }
                        }}
                        placeholder="Sets"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col lg={2} className="d-flex align-items-center">
                <Button
                  variant="outline-success"
                  size="sm"
                  className="float-end py-1 mt-3"
                  onClick={() => {
                    let sets = exercise_obj.aic_exercise.aic_exercise_details?.length + 1;
                    handleAddExerciseSets(
                      workoutID,
                      exercise_obj.aic_exercise?.id,
                      sets
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faAdd} /> Add Sets
                </Button>
              </Col>
            </Row>
          </div>

          <div>
            <Table
              striped
              bordered
              hover
              className="mb-0 exercise-sets-table"
              key={index}
            >
              <thead>
                <tr>
                  <th className="text-center">Sets</th>
                  <th>Workout Type</th>
                  <th className={(!exercise_obj.aic_exercise?.is_timed) ? "hide" : ""}>Duration</th>
                  <th className={(exercise_obj.aic_exercise?.is_timed) ? "hide" : ""}>Reps</th>
                  <th>Weight</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {exercise_obj.aic_exercise?.aic_exercise_details?.length ? (
                  exercise_obj.aic_exercise?.aic_exercise_details?.map((obj, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">{obj.sets}</td>
                        <td>{obj.aic_wtype?.name}</td>
                        <td className={(!exercise_obj.aic_exercise?.is_timed) ? "hide" : ""}>
                          {exercise_obj.aic_exercise?.is_timed
                            ? obj.duration + " Secs"
                            : "-"}{" "}
                        </td>
                        <td className={(exercise_obj.aic_exercise?.is_timed) ? "hide" : ""}>
                          {!exercise_obj.aic_exercise?.is_timed ? obj.reps : "-"}
                        </td>
                        <td>{obj.weight}</td>
                        <td className="text-center">
                          <Button
                            variant="outline-danger"
                            size="sm"
                            className="mx-2"
                            onClick={() => {
                              handleRemoveExerciseSets(obj.id, exercise_obj.aic_exercise?.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} /> Remove Set
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr key={index}>
                    <td colSpan={5} className="text-center">
                      No Activity Sets
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

const DragAndDropExercise = ({  
  workoutID,
  handleRemoveExercise,
  sortExericseOrder
}) => {
  const { workoutExerciseObj, setWorkoutExerciseObj, } = useContext(DataContext);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setWorkoutExerciseObj((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((card, index) => {
    return (
      <DragAndDrop
        key={card.id}
        index={index}
        workoutID={workoutID}
        id={card.exercise_id}
        exercise_name={card.name}
        moveCard={moveCard}
        exercise_obj={card}
        handleRemoveExercise={handleRemoveExercise}
      />
    );
  }, []);

 

  return (
    <>
      <Accordion defaultActiveKey="0" className="workout-exercise-mapping">
        <div>{workoutExerciseObj?.map((card, i) => renderCard(card, i))}</div>
      </Accordion>
      <Row>
        <Col md={12}>
          <Button
            variant="outline-danger"
            size="sm"
            className="float-end my-2"
            onClick={() => {
              sortExericseOrder();
            }}
          >
            <FontAwesomeIcon icon={faSave} /> Save Order
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DragAndDropExercise;
