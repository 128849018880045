import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Nav,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import DataContext from "../context/DataContext";
import Select from "react-select";
import API from "./api/API";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const ExerciseOutput = () => {
  const {
    workoutExerciseList,
    workoutsList,
    mapWorkoutExerciseList,
    setMapWorkoutExerciseList,
  } = useContext(DataContext);

  const [workoutSearch, setWorkoutSearch] = useState("");
  let categoryOptions = [];

  /*   useEffect(() => {
    if (workoutExerciseList) {
      Object.keys(workoutExerciseList).forEach(function (index) {
        categoryOptions.push({
          value: workoutExerciseList[index].id,
          label: workoutExerciseList[index].name,
        });
      });
    }
  }, [workoutExerciseList]); */

  function getCategoryOptions(workoutID) {
    const result = mapWorkoutExerciseList.filter(
      (data) => parseInt(data.workout_id) === parseInt(workoutID)
    );

    let exerciseListSelect = [];

    Object.keys(workoutExerciseList).forEach(function (index) {
      const temparray = result.filter(
        (ed) =>
          parseInt(ed.exercise_id) === parseInt(workoutExerciseList[index].id)
      );

      if (!temparray.length) {
        exerciseListSelect.push({
          value: workoutExerciseList[index].id,
          label:
            workoutExerciseList[index].name +
            " - [" +
            workoutExerciseList[index].difficulty +
            "]",
        });
      }
    });

    return exerciseListSelect;
  }

  return (
    <div className="WorkoutOutput">
      <Form.Control
        className="mb-3"
        type="text"
        id="searchWorkout"
        placeholder="Search Workout"
        value={workoutSearch}
        onChange={(e) => setWorkoutSearch(e.target.value)}
      />
     
      <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
        <Row>

          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              {workoutsList.length &&
                workoutsList
                  .filter((ed) =>
                    ed.name.toLowerCase().includes(workoutSearch.toLowerCase())
                  )
                  ?.map((obj, index) => (
                    <Nav.Item key={obj.id}>
                      <Nav.Link eventKey={obj.id} className="text-capitalize">
                        {" "}
                        {obj.name.replace(/_/g, " ")}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
            </Nav>
          </Col>

          <Col sm={9}>
            <Tab.Content>
              {workoutsList.length &&
                workoutsList
                  .filter((ed) =>
                    ed.name.toLowerCase().includes(workoutSearch.toLowerCase())
                  )
                  ?.map((obj, index) => (
                    <Tab.Pane eventKey={obj.id} key={index}>
                      <Row className="m-0 mb-3 bg-light border border-primary rounded p-3 ">
                        <Col md={8} className="text-start">
                          <ListGroup>
                            <h5 className="text-capitalize">
                              <b>{obj.name.replace(/_/g, " ")}</b>
                            </h5>
                            <h6>Workout Type: {obj.wtype_name}</h6>
                            <h6 className="mb-0">
                              Workout Category: {obj.category.toString()}
                            </h6>
                          </ListGroup>
                        </Col>
                        <Col md={4}>
                          <ListGroup className="text-end">
                            <h6>Difficulty: {obj.difficulty}</h6>
                            <h6>Duration: {obj.duration}</h6>
                            <h6 className="mb-0">Sets: {obj.sets}</h6>
                          </ListGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={8} className="text-end"></Col>
                        <Col md={4}>
                          <Select
                            className="mb-3 text-start"
                            name="exercises"
                            options={getCategoryOptions(obj.id)}
                            value=""
                            placeholder="Select Exercise"
                            onChange={(e) => {
                              API.post("/workout-exercise", {
                                workout_id: obj.id,
                                exercise_id: e.value,
                              })
                                .then((response) => {
                                  let responseData =
                                    response.data.data.aic_workout_exercise;

                                  let responseObj = {
                                    id: responseData.id,
                                    workout_id: obj.id,
                                    exercise_id: responseData.exercise_id,
                                    exercise_name: e.value,
                                  };

                                  setMapWorkoutExerciseList([
                                    ...mapWorkoutExerciseList,
                                    responseObj,
                                  ]);
                                  Swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: "Mapped Successfully",
                                    showConfirmButton: false,
                                    timer: 800
                                  })
                                })
                                .catch((error) => {
                                  Swal.fire(
                                    error.response.data.message,
                                    "",
                                    "warning"
                                  );
                                });
                            }}
                          />
                        </Col>
                      </Row>

                      <ListGroup>
                        {obj.exercise_obj?.length ? (
                          obj?.exercise_obj?.map((data, d_index) => {
                            return (
                              <ListGroup.Item
                                key={d_index}
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                              >
                                <div className="ms-2 me-auto width-100">
                                  <div>
                                    <Row>
                                      <Col md={5}>
                                        <div className="fw-bold">
                                          <h5 className=" text-primary">
                                            {data?.exercise_name}
                                          </h5>
                                          <h6 className="mb-0 exercise-description">
                                            {data?.exercise_description}
                                          </h6>
                                        </div>
                                      </Col>
                                      <Col md={6} className="text-end">
                                        <h6 className="width-100">
                                          <span className="text-success">
                                            Difficulty:
                                            {data?.exercise_difficulty?.toUpperCase()}
                                          </span>
                                        </h6>
                                        <h6 className="width-100 mb-0">
                                          <span className="text-danger">
                                            Equipment: {data?.exercise_equipment}
                                          </span>
                                        </h6>
                                      </Col>
                                      <Col
                                        md={1}
                                        className="delete-exercise-mapping"
                                      >
                                        <Button
                                          className="bg-danger border-danger"
                                          onClick={() => {
                                            let uncheckedObj =
                                              mapWorkoutExerciseList.filter(
                                                (Obj) =>
                                                  parseInt(Obj.workout_id) ===
                                                    parseInt(obj.id) &&
                                                  parseInt(Obj.exercise_id) ===
                                                    parseInt(data.exercise_id)
                                              );

                                            API.delete(
                                              `/workout-exercise/${uncheckedObj[0].id}`
                                            ).then((response) => {
                                              var filterDeleted =
                                                mapWorkoutExerciseList.filter(
                                                  (Obj) =>
                                                    parseInt(Obj.id) !==
                                                    parseInt(uncheckedObj[0].id)
                                                );

                                              setMapWorkoutExerciseList(
                                                filterDeleted
                                              );
                                              Swal.fire({
                                                position: "center",
                                                icon: "success",
                                                title: "Remove Successfully",
                                                showConfirmButton: false,
                                                timer: 800
                                              })
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </ListGroup.Item>
                            );
                          })
                        ) : (
                          <ListGroup.Item>No Exercises Mapped</ListGroup.Item>
                        )}
                      </ListGroup>
                    </Tab.Pane>
                  ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default ExerciseOutput;
